import React from 'react'

const Button: React.FC<{onClick?: () => void, className: string}> = ({onClick , children, className, ...props}) => {
    return (
        <button type="submit" onClick={onClick} className={`text-white py-2 px-6 rounded-md flex justify-between items-center focus:outline-none focus:ring-2 transition-all ${className}`} {...props}>
            {children}
        </button>
    )
}

export default Button
