import Authenticated from "Authenticated";
import Login from "components/Auth/Login";
import Register from "components/Auth/Register";
import PrivateRoutes from "components/PrivateRoutes";
import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { history } from "_utils/history";

function App() {
  return (
    <React.Suspense fallback={<p>loading...</p>}>
      <Router history={history}>
        <Switch>
          <PrivateRoutes path="/overview" component={Authenticated} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Redirect from="*" to="/overview" />
        </Switch>
      </Router>
    </React.Suspense>
  );
}

export default App;
