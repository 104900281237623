import React, {FC, useContext, useEffect} from 'react';
import { history } from "_utils/history";
import CardContent from "../Card/CardContent";
import RadioCardTitle from "../RadioCard/RadioCardTitle";
import RadioCardLayout from "../RadioCard/RadioCardLayout";
import {changeIntensity, hazardRiskLevel, timeFrame} from "../../_utils/site-data";
import RadioCard from "../RadioCard/RadioCard";
import RadioLayout from "../RadioCard/RadioLayout";
import Radio from "../RadioCard/Radio";
import {useFormik} from "formik";
import Button from "../Button";
import {SiteContext} from "../../context";

type PollT = { titles: Array<string>; forms: any; activeForm: number, setForm: (args: any) => void, setActiveForm: (args: any) => void }

const Poll: FC<PollT> = ({titles, forms, activeForm, setForm, setActiveForm}) => {
  const formLength = titles.length - 1
  const {dispatch}  = useContext(SiteContext);

  const handlePollSelection = useFormik({
    initialValues: forms[titles[activeForm]],
    validate: (values) => {
      const errors: any = {}
      if (!values.riskLevel) errors.riskLevel = ''
      if (!values.intensity) errors.intensity = ''
      if (!values.frequency) errors.frequency = ''
      if (!values.timeframe) errors.timeframe = ''
      return errors;
    },
    onSubmit: (values, {resetForm}) => {
      // goto next component
      if (formLength === activeForm) {
        // submit global form
        dispatch({type: 'FINISH_RISK', payload: {done: true, state: forms}})
        history.push("/overview/risk-profile", forms)
      } else {
        setActiveForm(activeForm + 1)
        setForm({...forms, [titles[activeForm]]: values})
      }
    }
  })

  const val = forms[titles[activeForm]]

  const back = () => {
    setActiveForm(activeForm - 1)
  }

  const handleSelection = (name: string, value: string) => () => {
    setForm({...forms, [titles[activeForm]]: {...forms[titles[activeForm]], [name]: value}})
    handlePollSelection.handleChange({target: {name, value}})
  }

  useEffect(() => {
    handlePollSelection.setValues(forms[titles[activeForm]])
    // eslint-disable-next-line
  }, [forms, handlePollSelection.values, activeForm])

  return (
    <CardContent className="mt-5">
      <RadioCardTitle title="Current Hazard Risk Level"/>
      <RadioCardLayout className="">
        {hazardRiskLevel.map(({value, color}, index) => (
          <RadioCard color={val.riskLevel === value ? color : ''} onClick={handleSelection('riskLevel', value)}
                     className="col-span-1">{value}</RadioCard>
        ))}
      </RadioCardLayout>
      <RadioCardTitle title="Expected Change in Intensity"/>
      <RadioCardLayout className="">
        {changeIntensity && changeIntensity.map(({value, color}, index) => (
          <RadioCard color={val.intensity === value ? color : ''} onClick={handleSelection('intensity', value)}
                     className="col-span-2">{value}</RadioCard>
        ))}
      </RadioCardLayout>
      <RadioCardTitle title="Expected Change in Frequency"/>
      <RadioCardLayout className="">
        {changeIntensity && changeIntensity.map(({value, color}, index) => (
          <RadioCard color={val.frequency === value ? color : ''} onClick={handleSelection('frequency', value)}
                     className="col-span-2">{value}</RadioCard>
        ))}
      </RadioCardLayout>
      <RadioCardTitle title="Timeframe"/>
      <RadioLayout className="">
        {timeFrame && timeFrame.map(({value, color}, index) => (
          <Radio color={val.timeframe === value ? color : ''} onClick={handleSelection('timeframe', value)}
                 className="col-span-1">{value}</Radio>
        ))}
      </RadioLayout>
      <div className="button mt-24 flex justify-end">
        {activeForm !== 0 && <span className="cursor-pointer text-main hover:text-primary-300 font-semibold mr-14 mt-2" onClick={back}>Back</span>}
        <Button
          onClick={handlePollSelection.handleSubmit}
          className="bg-main hover:bg-primary-300 font-semibold"
        >
          <span className="text-center px-5">{formLength === activeForm ? 'Finish' : 'Next'}</span>
        </Button>
      </div>
    </CardContent>
  );
};

export default Poll;