import React from 'react'

const RadioLayout: React.FC<{className: string}> = ({children, className, ...props}) => {
  return (
    <div className={`grid grid-cols-5 gap-5 my-5 ${className}`} {...props}>
      {children}
    </div>
  )
}

export default RadioLayout
