import React from 'react'
import { Link } from 'react-router-dom'

const CardTitle: React.FC<{title: string, children?: React.ReactChild, uppercase?: boolean, link?: string}> = ({title, children, uppercase, link}) => {
    return (
        <Link to={link ? link : '/'} className="flex justify-between items-center">
            <h2 className={`title text-primary-600 text-2xl font-semibold ${uppercase && "uppercase"}`}>{title}</h2>
            <div className="header-content">{children}</div>
        </Link>
    )
}

export default CardTitle
