import React from 'react';
import Up from "./Up";
import Down from "./Down";

const SortArrow = () => {
  return (
    <span className="flex flex-col mt-2">
      <span className="">
        <Up />
      </span>
      <span className="mt-1">
        <Down />
      </span>
    </span>
  );
};

export default SortArrow;
