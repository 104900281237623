import React from 'react';

const UpArrow = () => {
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.06647 0.309244L5.06547 0.310196L0.305287 5.00894C-0.103112 5.42165 -0.101593 6.08919 0.308851 6.5C0.719243 6.91076 1.38303 6.90918 1.79148 6.49647L4.76018 3.60798L4.76018 18.9457C4.76018 19.528 5.22954 20 5.80857 20C6.38759 20 6.85696 19.528 6.85696 18.9457L6.85696 3.60803L9.82595 6.49647C10.2344 6.90918 10.8982 6.91076 11.3086 6.5C11.7191 6.08919 11.7205 5.4216 11.3121 5.00894L6.55166 0.310139L6.55067 0.309191C6.14086 -0.103731 5.47492 -0.102411 5.06647 0.309244Z"
        fill="#C73C5C"/>
    </svg>
  );
};

export default UpArrow;
