import RoundAddIcon from "Assets/RoundAddIcon";
import SlackIcon from "Assets/SlackIcon";
import ZoomIcon from "Assets/ZoomIcon";
import IconTextButton from "components/IconTextButton";
import { Modal, ModalContent, ModalOpen } from "components/Modal";
import { integrationItemType } from "types";

const integrationItems = [
  {
    icon: <ZoomIcon />,
    title: "Zoom",
    content: "Zoom Stuffs",
  },
  {
    icon: <SlackIcon />,
    title: "Slack",
    content: "slack content",
  },
  {
    icon: <RoundAddIcon/>,
    title: "Add Plugin",
    content: "add plugin content",
  },
];

const Integration = () => {
  return (
    <div className="mt-8">
      <h3 className="integration-title text-white opacity-50 uppercase font-franklin">
        Integrations
      </h3>
      <div className="integration-menu mt-6">
        {integrationItems && integrationItems.length > 0 && (
            integrationItems.map( (integrationItem: integrationItemType, i: number) => (
                <Modal key={i}>
                    <ModalOpen>
                        <IconTextButton key={i} data={integrationItem}/>
                    </ModalOpen>
                    <ModalContent>
                        {integrationItem.content}
                    </ModalContent>
                </Modal>
            )))}
      </div>
    </div>
  );
};

export default Integration;
