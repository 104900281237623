import Overview from "pages/OverviewRoute";
import {Redirect, Route, Switch} from "react-router-dom";
import React, {useState} from "react";

const Main = () => {
  const [isActive, setActive] = useState(false)
  const haveSetup = () => {
    setActive(!isActive)
  }
  return (
    <main className="px-8 pt-14 relative">
      {isActive &&
        <div
          onClick={haveSetup}
          className="grid-general absolute top-0 right-0 left-0 bottom-0 px-8 w-full h-full"
          style={{padding: 143, background: 'rgba(0,125,255,0.2)' }}>
          <div className="row-span-2"/>
          <div className="row-span-3"/>
          <div className="row-span-3"/>
          <div className="row-span-1"/>
          <div/>
          <div className=""/>
          <div className="col-start-2 col-span-2 row-start-4 row-span-2"/>
          <div className="col-span-full mt-8"/>
        </div>
      }
      <Switch>
        <Route path="/overview">
          <Overview isActive={isActive} setActive={setActive}/>
        </Route>
        <Route path="/team"/>
        <Redirect exact from="/" to="/overview"/>
      </Switch>
    </main>
  );
};

export default Main;
