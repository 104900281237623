import React from 'react'

const CardContent:React.FC<{className?: string}> = ({children, className}) => {
    return (
        <div className={className}>
            {children}
        </div>
    )
}

export default CardContent
