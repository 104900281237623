import React, {useState} from 'react'
import GoogleIcon from 'Assets/GoogleIcon'
import Button from 'components/Button'
import Heading from 'components/Heading'
import {Link, Redirect} from 'react-router-dom'
import AuthLayout from "./AuthLayout";
import { useFormik } from 'formik';
import Success from "../Success";
import Input from "./Input";

const Login = () => {
  const [redirect, setRedirect] = useState(false)
  const loginForm = useFormik({
    initialValues: {email: '', password: ''},
    validate: (values: { email: any; password: any }) => {
      const errors: any = {}
      if (!values.email) errors.email = 'Email is required'
      if (!values.password) errors.password = 'Password is required'
      return errors;
    },
    onSubmit: (values: any, {resetForm, setSubmitting}: any) => {
      console.log(values)
      // resetForm()
      sessionStorage.setItem("user", JSON.stringify("user"));
      setTimeout(() => {
        setSubmitting(false)
        setRedirect(true)
      },3000);
    }
  })
  return (
    <AuthLayout>
      {redirect && <Redirect to="/overview?filled=false"/>}
      {loginForm.isSubmitting && <Success title="" />}
      <div className={loginForm.isSubmitting ? "opacity-40":""}>
        <div className="text-center mt-3 flex flex-col justify-center items-center">
          <Heading text="Login" type="medium"/>
          <p className="sub-heading mt-4 mb-12 text-darkGrey">Don't have an account? <Link to="/register" className="">Sign
            up</Link></p>
          <Button onClick={() => console.log("clicked")}
                  className="google-button bg-transparent font-medium text-secondary-200 border border-gray-300 w-full flex items-center">
            <span className="w-4 h-4"><GoogleIcon/></span>
            <span className="text-semibold inline-block ml-4">Sign in with Google</span>
          </Button>
          <span className="form_divider my-7 text-secondary-200 block w-full">Or</span>
        </div>
        <div>
          <form onSubmit={loginForm.handleSubmit}>
            <div className="flex flex-col mb-5 w-full">
              <label className="text-secondary-200 mb-2">Email</label>
              <Input
                  type="email"
                  name="email"
                  value={loginForm.values.email}
                  onChange={loginForm.handleChange}
                  onBlur={loginForm.handleBlur}
                  placeholder="ex.name@gmail.com"
              />
            </div>
            <div className="flex flex-col mb-5 w-full">
              <label className="text-secondary-200 mb-2">Password</label>
              <Input
                type="password"
                name="password"
                value={loginForm.values.password}
                onChange={loginForm.handleChange}
                onBlur={loginForm.handleBlur}
                placeholder="8+ characters, 1 Capital Letter"
              />
            </div>
            <div className="mt-10 mb-20">
              <Button className="google-button bg-main font-medium text-white border border-gray-300 w-full">
                <span className="text-semibold mr-3">Login</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </AuthLayout>
  )
}
export default Login
