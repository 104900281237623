type tableProps = {
  thead?: tableHeadProps[];
  tbody?: tableBodyProps[];
};

interface tableHeadProps {
  th: string;
}

interface tableBodyProps {
  tr: tableDataProps[];
}
interface tableDataProps {
  td: any;
}

const Table = ({ thead, tbody }: tableProps) => {
  return (
    <table className="table-auto w-full divide-y divide-gray-200 border-b-2 text-darkGrey">
      {thead && thead.length > 0 && (
        <thead>
          <tr className="py-4 font-franklin">
            {thead.map((tableHeadItem: tableHeadProps, index: number) => (
              <th
                scope="col"
                key={index}
                className="px-6 py-4 whitespace-nowrap text-left last:text-right first:pl-0"
              >
                {tableHeadItem.th}
              </th>
            ))}
          </tr>
        </thead>
      )}

      {tbody && tbody.length > 0 && (
        <tbody className="divide-y divide-gray-300">
          {tbody.map((tableBodyItem: tableBodyProps, index: number) => {
            const tr = tableBodyItem.tr;
            return (
              <tr className="py-4" key={index}>
                {tr &&
                  tr.length > 0 &&
                  tr.map((tableData: tableDataProps, i: number) => {
                    const td = tableData.td;
                    return (
                      <td className="px-6 py-4 whitespace-nowrap last:text-right first:pl-0" key={i}>
                        {td}
                      </td>
                    );
                  })}
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
};

export default Table;
