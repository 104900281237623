import Brand from "Assets/Brand";
import React from "react";
import {Link} from "react-router-dom";
import Card from "../Card";

const AuthLayout: React.FC = ({children}) => {
  return (
    <div className="app">
      <div className="unauthenticated-section col-start-2 col-end-12">
        <Link to="/" className="nav mt-8 block w-48">
          <Brand/>
        </Link>
        <div className="my-0 mx-auto w-1/3 mt-24">
          <Card>
            <div className="card-content w-4/5 my-0 mx-auto">
              {children}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
