import React from 'react'

const RadioCardTitle: React.FC<{title: string}> = ({title}) => {
  return (
    <span className="">
      {title}
    </span>
  )
}

export default RadioCardTitle
