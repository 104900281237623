const BellIcon = () => {
  return (
    <svg
      width="25"
      height="27"
      viewBox="0 0 25 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3603 26.6653C9.80554 26.6653 7.72516 24.5862 7.72516 22.0301C7.72516 21.5183 8.14046 21.103 8.65219 21.103C9.16391 21.103 9.57921 21.5183 9.57921 22.0301C9.57921 23.5641 10.8276 24.8112 12.3603 24.8112C13.893 24.8112 15.1414 23.5641 15.1414 22.0301C15.1414 21.5183 15.5567 21.103 16.0684 21.103C16.5801 21.103 16.9954 21.5183 16.9954 22.0301C16.9954 24.5862 14.9153 26.6653 12.3603 26.6653Z"
        fill="#C1C9CB"
      />
      <path
        d="M22.5579 22.9571H2.16301C0.970267 22.9571 0 21.9868 0 20.7941C0 20.1611 0.275667 19.5617 0.756387 19.15C0.787394 19.1229 0.820664 19.0982 0.855292 19.0758C2.66976 17.4924 3.70815 15.2144 3.70815 12.809V9.36047C3.70815 4.58948 7.59058 0.708191 12.3604 0.708191C12.5582 0.708191 12.7721 0.711812 12.9699 0.745309C13.4753 0.829276 13.8166 1.30773 13.7324 1.81199C13.6485 2.31625 13.1614 2.65755 12.6658 2.57336C12.5668 2.55729 12.4582 2.56227 12.3604 2.56227C8.6129 2.56227 5.56223 5.61158 5.56223 9.36047V12.809C5.56223 15.7954 4.25315 18.6211 1.97403 20.5603C1.95547 20.5752 1.9394 20.5888 1.91949 20.6024C1.88622 20.6445 1.85408 20.7088 1.85408 20.7941C1.85408 20.962 1.99508 21.103 2.16301 21.103H22.5579C22.726 21.103 22.867 20.962 22.867 20.7941C22.867 20.7074 22.8349 20.6445 22.8003 20.6024C22.7817 20.5888 22.7656 20.5752 22.7471 20.5603C20.4666 18.6198 19.1589 15.7954 19.1589 12.809V11.4619C19.1589 10.9502 19.5742 10.5349 20.0859 10.5349C20.5976 10.5349 21.0129 10.9502 21.0129 11.4619V12.809C21.0129 15.2158 22.0525 17.4949 23.8694 19.0797C23.9027 19.1018 23.9348 19.1254 23.9645 19.1514C24.4454 19.5617 24.7211 20.1611 24.7211 20.7941C24.7211 21.9868 23.7508 22.9571 22.5579 22.9571Z"
        fill="#C1C9CB"
      />
    </svg>
  );
};

export default BellIcon;
