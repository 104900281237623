import React from "react";
import SearchIcon from "Assets/SearchIcon";
import './search.css';
import { SearchType } from "types";

const SearchInput = ({onSubmit, name, placeholder, reverse}: SearchType) => {
  const [search, setSearch] = React.useState("");

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(search);
  };

  const handleSearchInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  return (
    <form className={`search-input-wrapper ${reverse && 'flex-row-reverse'}`} onSubmit={handleSearchSubmit}>
      <label htmlFor={name} className="search-icon mr-2">
        <SearchIcon />
      </label>
      <input
        type="search"
        name={name}
        id={name}
        placeholder={placeholder || 'Search'}
        value={search}
        onChange={handleSearchInputChange}
      />
    </form>
  );
};

export default SearchInput;
