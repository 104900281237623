import React, {createContext, Dispatch, FC, useReducer} from 'react';

export const SiteContext = createContext<{dispatch:  Dispatch<any>; state: {}} | any>({})

const initialState = {
  formCompleted: false,
  riskCompleted: {done: false, state:{}}
}


const SiteProvider:FC = ({children}) => {
  const reducer = (state:any, action: any) => {
    switch (action.type) {
      case 'SAVE_FORM':
        return {...state, formCompleted: action.payload}
      case 'FINISH_RISK':
        return {...state, riskCompleted: action.payload}
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)


  return (
    <SiteContext.Provider value={{state, dispatch}}>
      {children}
    </SiteContext.Provider>
  );
};

export default SiteProvider;