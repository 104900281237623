import Radar from "react-d3-radar";
import {FC} from "react";

const RadarChart: FC<{submitted: boolean}> = ({submitted}) => {
  return (
    <Radar
      width={400}
      height={400}
      padding={50}
      domainMax={3}
      highlighted={true}
      radius={20}
      scales={{}}
      style={{
        display: 'none'
      }}
      data={{
        variables: [
          { key: "System", label: "System" },
          { key: "Internal", label: "Internal" },
          { key: "External", label: "External" },
        ],
        sets: [
          {
            values: {
              System: `${submitted ? 3 : 0}`,
              Internal: `${submitted ? 1.8 : 0}`,
              External: `${submitted ? 2.8 : 0}`,
            },
          },
        ],
      }}
    />
  );
};

export default RadarChart;
