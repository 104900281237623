const MessageIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C5.83213 0 0 5.83213 0 13C0 15.4993 0.700375 17.8994 2.028 19.968L0.0585 24.8853C-0.06175 25.1875 0.00812498 25.532 0.238875 25.7628C0.39325 25.9171 0.60125 26 0.8125 26C0.914875 26 1.01562 25.9805 1.11475 25.9415L6.032 23.972C8.10063 25.2996 10.5007 26 13 26C20.1679 26 26 20.1679 26 13C26 5.83213 20.1679 0 13 0ZM13 24.375C10.6827 24.375 8.46462 23.6893 6.58775 22.3909C6.45125 22.295 6.28875 22.2463 6.12625 22.2463C6.02387 22.2463 5.9215 22.2658 5.824 22.3048L2.27175 23.7283L3.69525 20.176C3.796 19.9225 3.7635 19.6349 3.60912 19.4123C2.31075 17.5354 1.625 15.3173 1.625 13C1.625 6.7275 6.7275 1.625 13 1.625C19.2725 1.625 24.375 6.7275 24.375 13C24.375 19.2725 19.2725 24.375 13 24.375Z"
        fill="#C1C9CB"
      />
    </svg>
  );
};

export default MessageIcon;
