import HelpIcon from "Assets/HelpIcon";
import MenuDots from "Assets/MenuDots";
import RoundAddIcon from "Assets/RoundAddIcon";
import Card from "components/Card";
import CardContent from "components/Card/CardContent";
import CardTitle from "components/Card/CardTitle";
import CircularProgress from "components/Charts/CircularProgress";
import RadarChart from "components/Charts/RadarChart";
import Guage from "components/Charts/Guage";
import Table from "components/Charts/Table";
import DropDown from "components/DropdownMenu";
import SearchInput from "components/Search";
import {
  bigtableBody,
  bigTableDataHeader,
  dateItems,
  dateItemsFalse,
  tableExtra,
  tableExtraFalse,
} from "_utils/site-data";
import Layout from "components/Main/Layout";
import React, {FC, useContext} from "react";
import {Link} from "react-router-dom";
import PageHeading from "../components/Main/PageHeading";
import Button from "../components/Button";
import Edit from "../Assets/Edit";
import {SiteContext} from "../context";

const OverviewHome: FC<{ isActive: boolean, setActive: any; }> = ({isActive, setActive}) => {
  const {state} = useContext(SiteContext)

  const submitted = state.formCompleted;

  const showOverlay = (e?: any) => {
    if (!submitted) {
      setActive(true)
    }
  }

  const deActivate = (state: string) => (e: any) => {
    e.stopPropagation()
    setActive(false)
  }

  return (
    <div>
      <div className="main-header">
        <PageHeading>
          <SearchInput
            reverse
            onSubmit={(e) => console.log(e)}
            name="header-search"
            placeholder="Find a Location"
          />

          <div className="calendar ml-4">Date Range</div>

          <div className="button ml-8">
            <Button
              onClick={() => console.log("clicked")}
              className="bg-main hover:bg-primary-300 font-semibold"
            >
              <span className="mr-3">Customize</span>
              <Edit/>
            </Button>
          </div>
        </PageHeading>
      </div>
      <Layout id={'layout'} onClick={showOverlay} className={`${submitted ? "" : "z-10 cursor-pointer"}`}>
        <div className="row-span-2">
          <Card>
            <CardTitle title="Trenton Water Works">
              <MenuDots/>
            </CardTitle>
            <CardTitle title="ID: PWS234" uppercase/>
            <CardContent>
              <Table tbody={submitted ? tableExtra : tableExtraFalse}/>
            </CardContent>
          </Card>
        </div>

        <div className="row-span-3">
          <Card>
            <CardTitle title="Risk Ranking">
              <span className="flex justify-between items-center">
                <HelpIcon/>
                <div className="inline-block ml-4">
                  <DropDown items={submitted ? dateItems : dateItemsFalse}/>
                </div>
              </span>
            </CardTitle>
            <CardContent>
              <div className="mt-10">
                <CircularProgress value={submitted ? 60 : 0}/>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="row-span-3">
          <Card>
            <CardTitle title="Risk Analysis">
              <span className="flex justify-between items-center">
                <div className="inline-block ml-4">
                  <DropDown items={submitted ? dateItems : dateItemsFalse}/>
                </div>
              </span>
            </CardTitle>
            <CardContent>
              <div className="mt-0">
                <RadarChart submitted={submitted}/>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className={`row-span-1 ${isActive ? 'z-10 relative' : ''}`}>
          {isActive &&
          <div className="absolute right-0 left-0 -mt-16" id="pop-up" style={{marginLeft: '450px'}}>
            <div className="bg-white rounded-lg shadow-xl flex flex-col p-5"
                 style={{width: '270px', height: '220px'}}>
              <span style={{color: '#00465C'}}>Start With Risk Profile</span>
              <span className="my-4" style={{color: '#2E434A'}}>First complete your Internal risk profile to generate your dashboard</span>
              <hr/>
              <div className="mt-4 flex justify-between">
                <span id={'got'} onClick={deActivate('close')} className="cursor-pointer" style={{color: '#5C6D73'}}>Got It</span>
                <span className="cursor-pointer" style={{color: '#0088B2'}}>
                    <Link to='/overview/internal-risk-profile' id={'now'} onClick={deActivate('start')}>Start Now</Link></span>
              </div>
            </div>
            <div className="bg-white -ml-1 -mt-24 transform rotate-45" style={{width: '25px', height: '25px'}}/>
          </div>
          }
          <Card className={`${isActive ? 'px-3 bg-[#ff0000]' : 'px-0 py-0 disabled:shadow-md'}`}>
            <Card>
              <CardTitle title="Internal" link={submitted ? "/overview/internal-risk" : "/overview?filled=false"}>
                <MenuDots/>
              </CardTitle>
              <CardContent>
                <Guage percent={submitted ? 0.1 : 0} submitted={submitted}/>
              </CardContent>
            </Card>
          </Card>
        </div>

        <div>
          <Card>
            <CardTitle title="External" link={submitted ? "/overview/external-risk" : "/overview?filled=false"}>
              <MenuDots/>
            </CardTitle>
            <CardContent>
              <Guage percent={submitted ? 0.57 : 0} submitted={submitted}/>
            </CardContent>
          </Card>
        </div>

        <div className="">
          <Card>
            <CardTitle title="System" link={submitted ? "" : "/overview?filled=false"}>
              <MenuDots/>
            </CardTitle>
            <CardContent>
              <Guage percent={submitted ? 0.4 : 0} submitted={submitted}/>
            </CardContent>
          </Card>
        </div>

        <div className="col-start-2 col-span-2 row-start-4 row-span-2">
          <Card>
            <CardTitle title="Sensor Map" link={submitted ? "" : "/overview?filled=false"}>
              <span className="flex justify-between items-center">
                <HelpIcon/>
                <div className="inline-block ml-4">
                  <SearchInput
                    name="map-search"
                    onSubmit={() => console.log("submitted")}
                  />
                </div>
              </span>
            </CardTitle>
            <CardContent className="mt-4">
              <iframe
                src="https://www.3dcitydb.org/3dcitydb-web-map/1.7/3dwebclient/index.html?title=Berlin_Demo&batchSize=1&latitude=52.517479728958044&longitude=13.411141287558161&height=534.3099172951087&heading=345.2992773976952&pitch=-44.26228062802528&roll=359.933888621294&layer_0=url%3Dhttps%253A%252F%252Fwww.3dcitydb.org%252F3dcitydb%252Ffileadmin%252Fmydata%252FBerlin_Demo%252FBerlin_Buildings_rgbTexture_ScaleFactor_0.3%252FBerlin_Buildings_rgbTexture_collada_MasterJSON.json%26name%3DBrlin_Buildings_rgbTexture%26active%3Dtrue%26spreadsheetUrl%3Dhttps%253A%252F%252Fwww.google.com%252Ffusiontables%252FDataSource%253Fdocid%253D19cuclDgIHMqrRQyBwLEztMLeGzP83IBWfEtKQA3B%2526pli%253D1%2523rows%253Aid%253D1%26cityobjectsJsonUrl%3D%26minLodPixels%3D100%26maxLodPixels%3D1.7976931348623157e%252B308%26maxSizeOfCachedTiles%3D200%26maxCountOfVisibleTiles%3D200"
                height="350"
                width="100%"
                style={{border: "none;", opacity: `${submitted ? "" : "0.2"}`}}
                title="Sensor Map"
              />
            </CardContent>
          </Card>
        </div>

        {submitted ?
          <div className="col-span-full mt-8">
            <Card>
              <CardTitle title="Data">
              <span className="flex justify-between items-center">
                <div className="inline-block mr-4">
                  <SearchInput
                    placeholder="Search Data"
                    name="map-search"
                    onSubmit={() => console.log("submitted")}
                  />
                </div>
                <RoundAddIcon/>
              </span>
              </CardTitle>
              <CardContent>
                <div className="mt-6">
                  <Table thead={bigTableDataHeader} tbody={bigtableBody}/>
                </div>
              </CardContent>
            </Card>
          </div>
          :
          null
        }

      </Layout>
    </div>
  );
};

export default OverviewHome;
