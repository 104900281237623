import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

type contextProps = [isOpen: boolean, setIsOpen: (value: any) => void];
const modalContext = React.createContext<contextProps | boolean>(false);

const Modal: React.FC = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const value = [isOpen, setIsOpen] as contextProps;

  return <modalContext.Provider value={value} {...props} />;
};

function useModal() {
  const context = React.useContext(modalContext);
  if (!context) {
    throw new Error(`useModal must be used in or within a Modal Component`);
  }
  return context;
}

const ModalOpen = ({ children: child }: any) => {
  const [, setIsOpen] = useModal() as contextProps;

  function openModal() {
    setIsOpen(true);
  }
  return React.cloneElement(child, {
    onClick: () => openModal(),
  });
};

const ModalDismiss = ({ children: child }: any) => {
  const [, setIsOpen] = useModal() as contextProps;

  function dismissModal() {
    setIsOpen(false);
  }
  return React.cloneElement(child, {
    onClick: () => dismissModal(),
  });
};

const ModalContent: React.FC<{ title?: string }> = ({
  title = "",
  ...props
}) => {
  const [isOpen, setIsOpen] = useModal() as contextProps;

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="flex w-full justify-between items-center">
                {title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                )}
                <div
                  className={`close-btn text-right ${
                    title.length === 0 ? `flex w-full justify-end` : ``
                  }`}
                >
                  <button
                    onClick={() => setIsOpen(false)}
                    className="text-secondary-200 text-3xl"
                  >
                    &times;
                  </button>
                </div>
              </div>
              <div className="mt-2">{props.children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export { Modal, ModalOpen, ModalContent, ModalDismiss };
