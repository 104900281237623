import React from 'react'
import Unchecked from "../../Assets/Unchecked";

const RadioCard: React.FC<{onClick: () => void, className: string; color?:string}> = ({onClick , color, children, className, ...props}) => {
  return (
    <div onClick={onClick} className={`flex cursor-pointer rounded pl-8 py-2 items-center ${className}`} {...props}
         style={{border: `1px solid ${color ? `${color}` : 'gray'}`,  background: `${color ? `${color}2b` : ''}`, color: `${color ? `${color}` : 'gray'}`}}>
      <span>
        <Unchecked radius={color ? '6' : '8'} color={color ? color : '#dddddd'} strokeWidth={color ? '4' : '2'}/>
      </span>
      <span className="ml-5">{children}</span>
    </div>
  )
}

export default RadioCard
