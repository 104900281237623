import {
  VictoryChart,
  VictoryAxis,
  VictoryArea,
} from "victory";

const LIGHT_GREY = "#8D999E";

const sharedAxisStyles = {
  axis: {
    stroke: "transparent",
  },
  tickLabels: {
    fill: LIGHT_GREY,
    fontSize: 8,
    textTransform: "uppercase",
  },
};

const AreaChart = () => {
  return (
    <VictoryChart domainPadding={{ x: 5 }} height={210} minDomain={1}>
      <VictoryAxis
        tickValues={[ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        tickFormat={[
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
        ]}
        style={{
          ...sharedAxisStyles,
        }}
      />
      <VictoryAxis
        dependentAxis
        tickFormat={["0", "Low", "Medium", "High"]}
        style={{
          ...sharedAxisStyles,
          grid: {
            fill: LIGHT_GREY,
            stroke: LIGHT_GREY,
            pointerEvents: "painted",
            strokeWidth: 0.5,
          },
        }}
      />
      <VictoryArea
        style={{
          data: { fill: "#0088B2", strokeWidth: 1, fillOpacity: 0.4 },
        }}
        interpolation="natural"
        data={[
          { x: 0, y: 4 },
          { x: 1, y: 3.6 },
          { x: 2, y: 3.2 },
          { x: 3, y: 2.8 },
          { x: 4, y: 2.4 },
          { x: 5, y: 2 },
          { x: 6, y: 1.8 },
          { x: 7, y: 1.4 },
          { x: 8, y: 1 },
          { x: 9, y: 0 },
        ]}
      />
    </VictoryChart>
  );
};

export default AreaChart;
