import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

type ItemProps = {
  items: {
    name: string
  }[]
}
const DropDown = ({items}: ItemProps) => {
  const [selected, setSelected] = useState(items[0]);
  return (
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className="relative py-2 pl-3 pr-10 text-left bg-transparent border border-gray-300 rounded-sm shadow-sm cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected.name}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <svg
                width="9"
                height="5"
                viewBox="0 0 9 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.625 5L9 0H0.25L4.625 5Z"
                  fill="#8D999E"
                />
              </svg>
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {items.map((item: any, itemIdx: number) => (
                <Listbox.Option
                  key={itemIdx}
                  className={({ active }) =>
                    `${active ? "text-amber-900 bg-primary-200" : "text-gray-900"}
                        cursor-pointer select-none relative py-2 pl-2 pr-4`
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={`${selected ? "font-medium" : "font-normal"} block truncate`}>
                        {item.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        
        </div>
      </Listbox>
  );
};

export default DropDown;
