const SlackIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.082 15.2729C12.2818 15.2729 11.6328 15.9219 11.6328 16.7221V20.347C11.6328 21.1472 12.2818 21.7962 13.082 21.7962C13.8822 21.7962 14.5312 21.1472 14.5312 20.347V16.7221C14.5306 15.9219 13.8816 15.2729 13.082 15.2729Z"
        fill="#E91E63"
      />
      <path
        d="M8.00977 16.722C8.00977 17.5227 8.65933 18.1723 9.46009 18.1723C10.2608 18.1723 10.9104 17.5227 10.9104 16.722V15.2717H9.46124C9.46066 15.2717 9.46066 15.2717 9.46009 15.2717C8.65933 15.2717 8.00977 15.9212 8.00977 16.722Z"
        fill="#E91E63"
      />
      <path
        d="M13.0841 8.00006C13.0836 8.00006 13.083 8.00006 13.0824 8.00006C12.2816 8.00006 11.6321 8.64963 11.6321 9.45038C11.6321 10.2511 12.2816 10.9007 13.0824 10.9007H14.5316V9.45038C14.5316 9.44981 14.5316 9.44866 14.5316 9.44751C14.531 8.64791 13.8832 8.00006 13.0841 8.00006Z"
        fill="#00BCD4"
      />
      <path
        d="M9.45032 14.5363H13.0822C13.8829 14.5363 14.5325 13.8867 14.5325 13.0859C14.5325 12.2852 13.8829 11.6356 13.0822 11.6356H9.45032C8.64957 11.6356 8 12.2852 8 13.0859C8 13.8867 8.64957 14.5363 9.45032 14.5363Z"
        fill="#00BCD4"
      />
      <path
        d="M20.3332 11.6352C19.5336 11.6352 18.8857 12.283 18.8857 13.0826V13.0855V14.5358H20.3349C21.1357 14.5358 21.7852 13.8863 21.7852 13.0855C21.7852 12.2848 21.1357 11.6352 20.3349 11.6352C20.3343 11.6352 20.3338 11.6352 20.3332 11.6352Z"
        fill="#4CAF50"
      />
      <path
        d="M15.2654 9.45045V13.0863C15.2654 13.8865 15.9144 14.5355 16.7146 14.5355C17.5147 14.5355 18.1637 13.8865 18.1637 13.0863V9.45045C18.1637 8.65028 17.5147 8.00128 16.7146 8.00128C15.9144 8.00128 15.2654 8.65028 15.2654 9.45045Z"
        fill="#4CAF50"
      />
      <path
        d="M18.1635 20.3457C18.1635 19.5455 17.5145 18.8965 16.7143 18.8965H15.2651V20.3468C15.2657 21.1464 15.9141 21.7948 16.7143 21.7948C17.5145 21.7948 18.1635 21.1458 18.1635 20.3457Z"
        fill="#FF9800"
      />
      <path
        d="M20.3458 15.2717H16.714C15.9132 15.2717 15.2637 15.9212 15.2637 16.722C15.2637 17.5227 15.9132 18.1723 16.714 18.1723H20.3458C21.1466 18.1723 21.7962 17.5227 21.7962 16.722C21.7962 15.9212 21.1466 15.2717 20.3458 15.2717Z"
        fill="#FF9800"
      />
      <circle
        cx="14.5"
        cy="14.5"
        r="14.1282"
        stroke="white"
        strokeOpacity="0.5"
        strokeWidth="0.74359"
      />
    </svg>
  );
};

export default SlackIcon;
