import React from 'react'

type pillProps = {
    color?: string,
    bg?: string,
}
const Pill: React.FC<pillProps> = ({children, color, bg}) => {
    return (
        <span className={`rounded-lg flex justify-center text-sm items-center h-7 ${color ? color : "text-white"} ${bg ? bg : "text-red"}`}>
            {children}
        </span>
    )
}

export default Pill
