import { Route, Router, Switch, useRouteMatch } from "react-router-dom";
import { history } from "_utils/history";
import ExternalRisk from "./ExernalRoute";
import InternalRisk from "./InternalRisk";
import OverviewHome from "./OverviewHome";
import {FC} from "react";
import InternalRiskProfile from "./InternalRiskProfile";
import RiskProfile from "./RiskProfile";

const Overview: FC<{isActive: boolean, setActive: any}> = ({isActive, setActive}) => {
  let { path } = useRouteMatch();

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={path}>
          <OverviewHome isActive={isActive} setActive={setActive} />
        </Route>
        <Route path={`${path}/internal-risk`}>
          <InternalRisk />
        </Route>
        <Route path={`${path}/external-risk`}>
          <ExternalRisk />
        </Route>
        <Route path={`${path}/internal-risk-profile`}>
          <InternalRiskProfile />
        </Route>
        <Route path={`${path}/risk-profile`}>
          <RiskProfile />
        </Route>
      </Switch>
    </Router>
  );
};

export default Overview;
