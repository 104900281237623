import React from 'react'

const RadioCardLayout: React.FC<{className: string}> = ({children, className, ...props}) => {
  return (
    <div className={`grid grid-cols-4 gap-5 my-5 ${className}`} {...props}>
      {children}
    </div>
  )
}

export default RadioCardLayout
