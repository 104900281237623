const GoogleIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3577 7.02182L9.42353 7.02148C9.11733 7.02148 8.86914 7.26964 8.86914 7.57584V9.79099C8.86914 10.0971 9.11733 10.3454 9.4235 10.3454H13.3284C12.9008 11.455 12.1027 12.3844 11.0845 12.9748L12.7496 15.8572C15.4205 14.3125 16.9996 11.6021 16.9996 8.56809C16.9996 8.13608 16.9677 7.82726 16.904 7.47952C16.8556 7.21532 16.6263 7.02182 16.3577 7.02182Z"
        fill="#0088B2"
      />
      <path
        d="M8.50004 13.6736C6.58906 13.6736 4.9208 12.6295 4.02481 11.0845L1.14258 12.7458C2.60933 15.2879 5.35702 16.9997 8.50004 16.9997C10.0419 16.9997 11.4968 16.5846 12.75 15.8611V15.8572L11.085 12.9748C10.3234 13.4165 9.44204 13.6736 8.50004 13.6736Z"
        fill="#0088B2"
      />
      <path
        d="M12.75 15.8614V15.8575L11.085 12.9751C10.3234 13.4168 9.44207 13.6739 8.5 13.6739V17C10.0419 17 11.4968 16.5849 12.75 15.8614Z"
        fill="#0088B2"
      />
      <path
        d="M3.32609 8.49996C3.32609 7.55802 3.58315 6.67677 4.02479 5.91519L1.14255 4.25391C0.415106 5.50324 0 6.95415 0 8.49996C0 10.0458 0.415106 11.4967 1.14255 12.746L4.02479 11.0847C3.58315 10.3231 3.32609 9.4419 3.32609 8.49996Z"
        fill="#0088B2"
      />
      <path
        d="M8.50004 3.32609C9.74618 3.32609 10.8908 3.76889 11.7849 4.50544C12.0055 4.68713 12.326 4.67401 12.5281 4.47197L14.0976 2.90246C14.3268 2.67322 14.3105 2.29799 14.0656 2.08556C12.5677 0.786019 10.6186 0 8.50004 0C5.35702 0 2.60933 1.71186 1.14258 4.25396L4.02481 5.91524C4.9208 4.3702 6.58906 3.32609 8.50004 3.32609Z"
        fill="#0088B2"
      />
      <path
        d="M11.7849 4.50544C12.0054 4.68713 12.326 4.67401 12.528 4.47197L14.0976 2.90246C14.3268 2.67322 14.3104 2.29799 14.0656 2.08556C12.5676 0.785986 10.6186 0 8.5 0V3.32609C9.74611 3.32609 10.8908 3.76889 11.7849 4.50544Z"
        fill="#0088B2"
      />
    </svg>
  );
};

export default GoogleIcon;
