const AlertIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7706 14.5586L12.6384 1.44782C11.4925 -0.481324 8.69913 -0.483887 7.55169 1.44782L0.419847 14.5586C-0.751574 16.5299 0.666663 19.0261 2.96262 19.0261H17.2272C19.5213 19.0261 20.942 16.5319 19.7706 14.5586ZM10.0951 16.6601C9.44289 16.6601 8.91204 16.1292 8.91204 15.4771C8.91204 14.8249 9.44289 14.2941 10.0951 14.2941C10.7472 14.2941 11.2781 14.8249 11.2781 15.4771C11.2781 16.1292 10.7472 16.6601 10.0951 16.6601ZM11.2781 11.928C11.2781 12.5802 10.7472 13.111 10.0951 13.111C9.44289 13.111 8.91204 12.5802 8.91204 11.928V6.01295C8.91204 5.3608 9.44289 4.82994 10.0951 4.82994C10.7472 4.82994 11.2781 5.3608 11.2781 6.01295V11.928Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AlertIcon;
