import React from 'react'

function useLocalStorage(key: string | number | any, defaultValue="", {serialize = JSON.stringify, deserialize = JSON.parse} = {}){
    const [state, setState] = React.useState(() => {
            const valueInLocalStorage = window.sessionStorage.getItem(key);

            if(valueInLocalStorage){
                return deserialize(valueInLocalStorage)
            }
            return defaultValue;
        }
    )

    React.useEffect(() => {
        window.sessionStorage.setItem(key, serialize(state))
    }, [key, serialize, state])

    return [state, setState];
}

export default useLocalStorage
