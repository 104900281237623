import React from 'react'
import { TitleProps } from 'types';

const Heading: React.FC<TitleProps> = ({text, type}) => {
    const HeadingTag = type === "large" ? 'h1': type === "medium" ? "h2": "h3";
    return (
        <HeadingTag className={`title text-primary-600 font-semibold ${type === "large" ? "text-5xl" : type==="medium" ? "text-3xl": 'text-2xl'}`}>{text}</HeadingTag>
    )
}

export default Heading
