import { ErrorBoundary } from "react-error-boundary";
import Main from "components/Main";
import Nav from "components/Nav";
import SideBar from "components/SideBar";

function ErrorFallback({ error }: { error: { message: string } }) {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "red",
      }}
    >
      Error: {error.message}
    </div>
  );
}

const Authenticated = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="app">
        <section className="sidebar">
          <SideBar />
        </section>
        <section className="nav">
          <Nav />
        </section>
        <section className="main">
          <Main />
        </section>
      </div>
    </ErrorBoundary>
  );
};

export default Authenticated;
