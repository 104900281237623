import React from 'react'

const Layout: React.FC<{className?: string, onClick?: () => void; id?: any}> = ({id, onClick, children, className}) => {
    return (
        <div id={id} onClick={onClick} className={`main-content mt-10 grid-general mb-20 ${className ?? ''}`}>
            {children}
        </div>
    )
}

export default Layout
