import Edit from "Assets/Edit";
import React from "react";
import { Link } from "react-router-dom";

const Notes = ({ data }: any) => {
  return (
    <ul className="divide divide-y text-darkGrey">
      {data &&
        data.length > 0 &&
        data.map((row: any, index: number) => (
          <li className="flex mb-1 items-baseline">
            <span className="dot">
              <svg
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="3" cy="3" r="3" fill="#2E434A" />
              </svg>
            </span>
            <span className="content py-3 pl-5 flex flex-col justify-center">
              <span className="headers flex">
                <span className="title font-semibold leading-6 mb-1">{row.title}</span>
                <span className="link inline-block ml-2">
                    <Link to ={row.link} className=" text-secondary-200">
                        <Edit/>
                    </Link>
                </span>
              </span>
              <span className="content">{row.description}</span>
            </span>
          </li>
        ))}
    </ul>
  );
};

export default Notes;
