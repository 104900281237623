import React, {useState} from 'react'
import Heading from "../Heading";
import Button from "../Button";
import Success from "../Success";
import { useFormik } from "formik";
import Input from "./Input";
import {Redirect} from "react-router-dom";

const AccountDetails = () => {
  const [redirect, setRedirect] = useState(false)

  const accountForm = useFormik({
    initialValues: {full_name: '', system: '', role: {label:'',  value:''}},
    validate: (values: { full_name: any; system: any; role: any; }) => {
      console.log(values)
      const errors: any = {}
      if (!values.full_name) errors.full_name = 'Full name is required'
      if (!values.system) errors.system = 'System is required'
      if (!values.role) errors.role = 'role is required'
      return errors;
    },
    onSubmit: (values: any, {resetForm, setSubmitting}: any) => {
      console.log(values)
      // resetForm()
      sessionStorage.setItem("user", JSON.stringify("user"));
      setTimeout(() => {
        setSubmitting(false)
        setRedirect(true)
      },3000);
    }
  })

  return (
    <div>
      {redirect && <Redirect to="/overview?filled=false"/>}
      {accountForm.isSubmitting && <Success title="Creating Your Account..." />}
      <div className={accountForm.isSubmitting ? "opacity-40":""}>
        <div className="text-center mt-3">
          <Heading text="Account Details" type="medium"/>
        </div>
        <div className="mt-16">
          <form onSubmit={accountForm.handleSubmit}>
            <div className="flex flex-col mb-5 w-full">
              <label className="text-secondary-200 mb-2">Full Name</label>
              <Input
                type="text"
                name="full_name"
                value={accountForm.values.full_name}
                onChange={accountForm.handleChange}
                onBlur={accountForm.handleBlur}
                placeholder="Your Name"
              />
            </div>
            <div className="flex flex-col mb-5 w-full">
              <label className="text-secondary-200 mb-2">Your Role</label>
              <Input.Select
                accountForm={accountForm}
                name={"role"}
              />
            </div>
            <div className="flex flex-col mb-5 w-full">
              <label className="text-secondary-200 mb-2">System/City</label>
                <Input
                  type="text"
                  name="system"
                  value={accountForm.values.system}
                  onChange={accountForm.handleChange}
                  onBlur={accountForm.handleBlur}
                  placeholder="ex. Trenton Water Works"
                />
            </div>
            <div className="mt-16 mb-20">
              <Button className="google-button bg-main font-medium text-white border border-gray-300 w-full">
                <span className="text-semibold mr-3">Finish</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AccountDetails