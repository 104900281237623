import { NavLink} from 'react-router-dom'
import { MenuItemType } from 'types'

const IconTextLink = ({menuData}: { menuData : MenuItemType }) => {
    const {menuLink, menuIcon, menuLabel} = menuData;
    return (
        <NavLink strict to={menuLink} className="flex transition items-center text-white opacity-50 hover:opacity-100 mb-10" activeClassName="text-primary-100 opacity-100">
            <span className="menu-item-icon">{menuIcon}</span>
            <div className="menu-item-label ml-4">{menuLabel}</div>
        </NavLink>
    )
}

export default IconTextLink
