import MenuDots from "Assets/MenuDots";
import RoundAddIcon from "Assets/RoundAddIcon";
import Card from "components/Card";
import CardContent from "components/Card/CardContent";
import CardTitle from "components/Card/CardTitle";
import Guage from "components/Charts/Guage";
import NetworkChart from "components/Charts/Network";
import Notes from "components/Charts/Notes";
import ScatterPlot from "components/Charts/ScatterPlot";
import Table from "components/Charts/Table";
import DropDown from "components/DropdownMenu";
import Layout from "components/Main/Layout";
import SearchInput from "components/Search";
import {
  dateItems,
  tableExtra,
  externalRiskNotes,
} from "_utils/site-data";
import {useLocation} from "react-router-dom";

const ExternalRisk = () => {
  let {search: submitted} = useLocation();

  return (
    <Layout className="">
      <div className="row-span-2">
        <Card>
          <CardTitle title="Trenton Water Works">
            <MenuDots />
          </CardTitle>
          <CardTitle title="ID: PWS234" uppercase />
          <CardContent>
            <Table tbody={tableExtra} />
          </CardContent>
        </Card>
      </div>

      <div className="row-span-2">
        <Card>
          <CardTitle title="Internal">
            <span className="flex justify-between items-center">
              <DropDown items={dateItems} />
              <div className="inline-block ml-4">
                <MenuDots />
              </div>
            </span>
          </CardTitle>
          <CardContent>
            <Guage percent={0.1} submitted={Boolean(submitted)}/>
          </CardContent>
        </Card>
      </div>

      <div className="row-span-4 relative">
        <Card>
          <CardTitle title="Risks Connection" />
          <CardContent className="mt-6">
            <NetworkChart />
          </CardContent>
        </Card>
      </div>

      <div className="col-start-1 col-span-2 row-start-3 row-span-2">
        <Card>
          <CardTitle title="Risk Probability and Impact">
            <DropDown items={dateItems} />
          </CardTitle>
          <CardContent className="-mt-8">
            <ScatterPlot />
          </CardContent>
        </Card>
      </div>
      <div className="col-span-full">
        <Card>
          <CardTitle title="Notes">
            <span className="flex justify-between items-center">
              <div className="inline-block mr-4">
                <SearchInput
                  placeholder="Search Notes"
                  name="map-search"
                  onSubmit={() => console.log("submitted")}
                />
              </div>
              <RoundAddIcon />
            </span>
          </CardTitle>
          <CardContent>
            <Notes data={externalRiskNotes}/>
          </CardContent>
        </Card>
      </div>
    </Layout>
  );
};

export default ExternalRisk;
