import PageHeading from "../components/Main/PageHeading";
import { history } from "_utils/history";
import Button from "../components/Button";
import Layout from "../components/Main/Layout";
import CardContent from "../components/Card/CardContent";
import Card from "../components/Card";
import React, { FC, useContext, useState } from "react";
import SortArrow from "../Assets/SortArrow";
import UpArrow from "../Assets/UpArrow";
import DownArrow from "../Assets/DownArrow";
import SearchInput from "../components/Search";
import PlusIcon from "../Assets/PlusIcon";
import MenuDots from "../Assets/MenuDots";
import Down from "../Assets/Down";
import { SiteContext } from "../context";

const RiskProfile: FC = () => {
  const hazardRiskLevel = [
    { value: "Low", color: "#74A242" },
    { value: "Moderate", color: "#F4A335" },
    { value: "High", color: "#C73C5C" },
    { value: "Not Known", color: "#8D999E" },
  ];
  const [openMenu, setOpenMenu] = useState({ isOpen: false, key: -1 });
  const [editMenu, setEditMenu] = useState({ isEdit: false, key: -1 });
  const [editHazard, setEditHazard] = useState({
    isEditHazard: false,
    key: -1,
  });
  const {
    dispatch,
    state: { riskCompleted },
  } = useContext(SiteContext);

  const internalRiskProfile = riskCompleted.state;

  const bgColor = (bgColor: string) => {
    return hazardRiskLevel.reduce((color, cur) => {
      if (cur.value === bgColor) {
        color = cur.color;
      }
      return color;
    }, "");
  };

  const openHazardMenu = (index: number) => {
    if (openMenu.isOpen) {
      if (index >= 0) setOpenMenu({ ...openMenu, key: index });
      else setOpenMenu({ isOpen: false, key: index });
    } else setOpenMenu({ isOpen: true, key: index });
  };

  const closeAllDropDownMenu = () => {
    if (openMenu.isOpen) {
      setOpenMenu({ isOpen: false, key: -1 });
    } else if (editHazard.isEditHazard) {
      setEditHazard({ isEditHazard: false, key: -1 });
    }
  };

  const edit = (index: number) => {
    if (editMenu.isEdit) {
      if (index >= 0) {
        setEditMenu({ ...editMenu, key: index });
        setOpenMenu({ isOpen: false, key: -1 });
      } else setEditMenu({ isEdit: false, key: index });
    } else {
      setEditMenu({ isEdit: true, key: index });
      setOpenMenu({ isOpen: false, key: -1 });
    }
  };
  const move = () => {};
  const remove = () => {};

  const save = () => {
    dispatch({ type: "SAVE_FORM", payload: true });
    setTimeout(() => history.push("/overview", internalRiskProfile), 500);
  };

  const openDropdown = (index: number) => {
    if (editHazard.isEditHazard) {
      if (index >= 0) setEditHazard({ ...editHazard, key: index });
      else setEditHazard({ isEditHazard: false, key: index });
    } else setEditHazard({ isEditHazard: true, key: index });
  };

  const handleChange =
    (objValue: any) =>
    ({ target }: any) => {
      const { value, name } = target;
      internalRiskProfile[objValue] = {
        ...internalRiskProfile[objValue],
        [name]: value,
      };
      dispatch({
        type: "FINISH_RISK",
        payload: { done: true, state: internalRiskProfile },
      });
    };

  const ProfileHeading = ({title}: {title: string}) => {
    return (
      <>
        <span className="pt-1 font-franklin profile-heading">{title}</span>
        <SortArrow />
      </>
    );
  };

  const updateRiskLevel = (
    key: number,
    riskLvlIndex: number,
    value: string
  ) => {
    // for (const name in internalRiskProfile){
    //   console.log(internalRiskProfile[name])
    // }
    // const _internalRiskProfile = internalRiskProfile.filter((obj: any, index: number) => index === key).map((obj: any, key: number) => {
    //   if (key === riskLvlIndex) {
    //     obj[0] = value
    //   }
    // })
    // setInternalRiskProfile({...internalRiskProfile, _internalRiskProfile})
  };

  return (
    <div>
      <div className="main-header">
        <PageHeading>
          <div className="button ml-8">
            <Button
              onClick={save}
              className="bg-main hover:bg-primary-300 font-semibold"
            >
              <span className="">Save</span>
            </Button>
          </div>
        </PageHeading>
      </div>
      <Layout>
        <div className="col-span-3">
          <Card className="px-0">
            <div className="flex justify-between px-5 pb-3">
              <h2 className="title text-primary-500 text-2xl font-semibold">
                Internal Risk
              </h2>
              <div className="flex justify-between">
                <div>
                  <SearchInput
                    onSubmit={(e) => console.log(e)}
                    name="header-search"
                    placeholder="Search"
                  />
                </div>
                <div
                  onClick={(e) => console.log(e)}
                  className="ml-5 cursor-pointer rounded-full h-10 w-10 border-dashed border-2 border-blue-400 bg-blue-200 flex items-center justify-center"
                >
                  <PlusIcon />
                </div>
              </div>
            </div>
            <CardContent>
              <table className="table-auto">
                <thead>
                  <tr className="grid grid-cols-12 gap-4">
                    <th className="text-left px-6 py-5 col-span-2">
                      <div className="flex">
                        <ProfileHeading title="Climate Hazard Type" />
                      </div>
                    </th>
                    <th className="text-left px-6 py-5 col-span-2">
                      <div className="flex">
                        <ProfileHeading title="Current Hazard Risk Level" />
                      </div>
                    </th>
                    <th className="text-left px-6 py-5 col-span-2">
                      <div className="flex">
                        <ProfileHeading title="Expected Change In Intensity" />
                      </div>
                    </th>
                    <th className="text-left px-6 py-5 col-span-2">
                      <div className="flex">
                        <ProfileHeading title="Expected Change In Frequency" />
                      </div>
                    </th>
                    <th className="text-left px-6 py-5 col-span-2">
                      <div className="flex">
                        <ProfileHeading title="Timeframe" />
                      </div>
                    </th>
                    <th className="text-left px-2 py-5 col-span-1">
                      <span className="flex">
                        <ProfileHeading title="Risk-related Indicators" />
                      </span>
                    </th>
                    <th className="col-span-1" />
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(internalRiskProfile).map((value, index) => {
                    const { riskLevel, intensity, frequency, timeframe } =
                      internalRiskProfile[value];
                    return (
                      <div
                        key={index}
                        className="px-5"
                        onClick={closeAllDropDownMenu}
                        style={{
                          background: `${
                            editMenu.isEdit && editMenu.key === index
                              ? "#F8FAFB"
                              : ""
                          }`,
                        }}
                      >
                        <hr className="px-5" />
                        <tr key={index} className="grid grid-cols-12">
                          <td className="text-left py-5 col-span-2 mt-2">
                            {value}
                          </td>
                          <td className="text-left px-3 py-5 col-span-2">
                            {editMenu.isEdit && editMenu.key === index ? (
                              <div>
                                <span
                                  className="flex cursor-pointer"
                                  onClick={() => openDropdown(index)}
                                >
                                  <div
                                    className="rounded-lg text-center p-2 w-3/5"
                                    style={{
                                      background: `${bgColor(riskLevel)}2b`,
                                      color:
                                        riskLevel !== "Not Known"
                                          ? bgColor(riskLevel)
                                          : "gray",
                                    }}
                                  >
                                    {riskLevel}
                                  </div>
                                  <span className="mt-5 ml-3">
                                    <Down />
                                  </span>
                                </span>
                                <div className="relative">
                                  {editHazard.isEditHazard &&
                                  editHazard.key === index ? (
                                    <div className="absolute bg-white rounded-md w-32 z-10 -top-2 right-0 left-0 flex flex-col text-center border shadow-md p-2">
                                      {hazardRiskLevel
                                        .filter(
                                          (level) => level.value !== riskLevel
                                        )
                                        .map(
                                          ({ value, color }, riskLvlIndex) => (
                                            <div
                                              className="py-2"
                                              key={riskLvlIndex}
                                            >
                                              <span
                                                className="text-sm cursor-pointer"
                                                onClick={() => {
                                                  updateRiskLevel(
                                                    index,
                                                    riskLvlIndex,
                                                    value
                                                  );
                                                }}
                                              >
                                                {value}
                                              </span>
                                            </div>
                                          )
                                        )}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              <div
                                className="rounded-lg text-center p-2 w-3/5"
                                style={{
                                  background: `${bgColor(riskLevel)}2b`,
                                  color:
                                    riskLevel !== "Not Known"
                                      ? bgColor(riskLevel)
                                      : "gray",
                                }}
                              >
                                {riskLevel}
                              </div>
                            )}
                          </td>
                          <td className="text-left px-3 py-5 col-span-2">
                            {editMenu.isEdit && editMenu.key === index ? (
                              <div>
                                <span
                                  className="flex cursor-pointer"
                                  onClick={() => openDropdown(index)}
                                >
                                  <div className="flex">
                                    <div
                                      className="rounded-md text-center p-2"
                                      style={{
                                        color:
                                          intensity === "Not Known"
                                            ? "gray"
                                            : "black",
                                      }}
                                    >
                                      {intensity}
                                    </div>
                                    <div className="mt-2">
                                      {intensity === "Increase" ? (
                                        <UpArrow />
                                      ) : intensity === "Decrease" ? (
                                        <DownArrow />
                                      ) : null}
                                    </div>
                                  </div>
                                  <span className="mt-5 ml-3">
                                    <Down />
                                  </span>
                                </span>
                                <div className="relative">
                                  {editHazard.isEditHazard &&
                                  editHazard.key === index ? (
                                    <div className="absolute bg-white rounded-md w-32 z-10 -top-2 right-0 left-0 flex flex-col text-center border shadow-md p-2">
                                      {hazardRiskLevel
                                        .filter(
                                          (level) => level.value !== intensity
                                        )
                                        .map(
                                          ({ value, color }, riskLvlIndex) => (
                                            <div
                                              className="py-2"
                                              key={riskLvlIndex}
                                            >
                                              <span
                                                className="text-sm cursor-pointer"
                                                onClick={() => {
                                                  updateRiskLevel(
                                                    index,
                                                    riskLvlIndex,
                                                    value
                                                  );
                                                }}
                                              >
                                                {value}
                                              </span>
                                            </div>
                                          )
                                        )}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              <div className="flex">
                                <div
                                  className="rounded-md text-center p-2"
                                  style={{
                                    color:
                                      intensity === "Not Known"
                                        ? "gray"
                                        : "black",
                                  }}
                                >
                                  {intensity}
                                </div>
                                <div className="mt-2">
                                  {intensity === "Increase" ? (
                                    <UpArrow />
                                  ) : intensity === "Decrease" ? (
                                    <DownArrow />
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left px-3 py-5 col-span-2">
                            <div className="flex">
                              <div
                                className="rounded-md text-center p-2"
                                style={{
                                  color:
                                    frequency === "Not Known"
                                      ? "gray"
                                      : "black",
                                }}
                              >
                                {frequency}
                              </div>
                              <div className="mt-2">
                                {frequency === "Increase" ? (
                                  <UpArrow />
                                ) : frequency === "Decrease" ? (
                                  <DownArrow />
                                ) : null}
                              </div>
                            </div>
                          </td>
                          <td className="text-left px-3 py-5 col-span-2">
                            <div
                              className="rounded-md text-center p-2 w-4/5"
                              style={{
                                color:
                                  timeframe === "Not Known" ? "gray" : "black",
                              }}
                            >
                              {timeframe}
                            </div>
                          </td>

                          <td className="text-left px-3 py-5 col-span-1">
                            {editMenu.isEdit && editMenu.key === index ? (
                              <input
                                name="indicator"
                                value={internalRiskProfile[value].indicator}
                                onChange={handleChange(value)}
                                placeholder=""
                                className="rounded-md p-5"
                                style={{
                                  width: "140px",
                                  height: "30px",
                                  border: "1px solid #0088B2",
                                }}
                              />
                            ) : (
                              <span className="mt-5">
                                {internalRiskProfile[value].indicator}
                              </span>
                            )}
                          </td>
                          <td className="flex justify-end col-span-1 mr-4 mt-7 relative">
                            {openMenu.isOpen && openMenu.key === index ? (
                              <div className="absolute bg-white rounded-md -top-13 bottom-0 right-2 left-0 flex flex-col text-center border shadow-md p-2">
                                <span
                                  className="py-2 text-sm cursor-pointer"
                                  onClick={() => edit(index)}
                                >
                                  Edit
                                </span>
                                <hr />
                                <span
                                  className="py-2 text-sm cursor-pointer"
                                  onClick={move}
                                >
                                  Move
                                </span>
                                <hr />
                                <span
                                  className="py-2 text-sm cursor-pointer"
                                  onClick={remove}
                                >
                                  Delete
                                </span>
                              </div>
                            ) : null}
                            <MenuDots onClick={() => openHazardMenu(index)} />
                          </td>
                        </tr>
                        <hr />
                      </div>
                    );
                  })}
                </tbody>
              </table>
            </CardContent>
          </Card>
        </div>
      </Layout>
    </div>
  );
};
export default RiskProfile;
