import LogOut from "Assets/LogOutIcon";
import SettingsIcons from "Assets/SettingsIcon";
import IconTextButton from "components/IconTextButton";
import { Modal, ModalContent, ModalOpen } from "components/Modal";

const settingsItems = [
  {
    icon: <SettingsIcons />,
    title: "Settings",
    content: "Settings Stuffs",
  },
  {
    icon: <LogOut />,
    title: "Logout",
    content: "Logout Stuffs",
  },
];
const Settings = () => {
  return (
    <div>
      {settingsItems &&
        settingsItems.length > 0 &&
        settingsItems.map((settingsItem, index) => (
          <Modal key={index}>
            <ModalOpen>
              <IconTextButton data={settingsItem} />
            </ModalOpen>
            <ModalContent>{settingsItem.content}</ModalContent>
          </Modal>
        ))}
    </div>
  );
};

export default Settings;
