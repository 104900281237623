import Brand from "Assets/Brand";
import Integration from "components/Integration";
import Menu from "components/Menu";
import Settings from "components/Settings";
import { Link } from "react-router-dom";

const SideBar = () => {
  return (
    <div className="sidebar lg:px-10 md:px-6 pt-16 relative h-screen flex flex-col justify-between">
      <div className="top-row">
        <Link to="/" className="logo-container block h-full max-h-8">
          <Brand />
        </Link>
        <Menu />
        <Integration />
        </div>
        <div className="bottom-row flex items-end">
          <Settings />
        </div>
      </div>
  );
};

export default SideBar;
