import React from "react";

const PlusIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14.2857 6.78571H8.21429V0.714287C8.21429 0.320009 7.89501 0 7.5 0C7.10499 0 6.78571 0.320009 6.78571 0.714287V6.78571H0.714287C0.319277 6.78571 0 7.10572 0 7.5C0 7.89428 0.319277 8.21429 0.714287 8.21429H6.78571V14.2857C6.78571 14.68 7.10499 15 7.5 15C7.89501 15 8.21429 14.68 8.21429 14.2857V8.21429H14.2857C14.6807 8.21429 15 7.89428 15 7.5C15 7.10572 14.6807 6.78571 14.2857 6.78571Z"
            fill="#0088B2"/>
    </svg>
  )
}

export default PlusIcon;
