const RoundAddIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14.5"
        cy="14.5"
        r="13.5"
        fill="#0088B2"
        fillOpacity="0.17"
        stroke="#0088B2"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4633 13.8723H15.1278V8.53683C15.1278 8.19034 14.8472 7.90912 14.5001 7.90912C14.153 7.90912 13.8724 8.19034 13.8724 8.53683V13.8723H8.53689C8.18976 13.8723 7.90918 14.1535 7.90918 14.5C7.90918 14.8465 8.18976 15.1277 8.53689 15.1277H13.8724V20.4632C13.8724 20.8097 14.153 21.0909 14.5001 21.0909C14.8472 21.0909 15.1278 20.8097 15.1278 20.4632V15.1277H20.4633C20.8104 15.1277 21.091 14.8465 21.091 14.5C21.091 14.1535 20.8104 13.8723 20.4633 13.8723Z"
        fill="#0088B2"
      />
    </svg>
  );
};

export default RoundAddIcon;
