import React from "react";

const Down = () => {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 4.6665L8 0.666504H0L4 4.6665Z" fill="#2E434A"/>
    </svg>
  )
}
export default Down;
