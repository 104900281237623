import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ value }: { value: number }) => {
  let rank, score, color, hex;
  let date = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
})

  switch (true) {
    case value === 0:
      rank = "";
      score = value;
      color = "";
      hex = '';
      date = ''
      break;
    case value > 0 && value <= 33:
      rank = "Low";
      score = value / 10;
      color = "text-green";
      hex = '#74A242';
      break;
    case value > 33 && value <= 60:
      rank = "Medium";
      score = value / 10;
      color = "text-yellow";
      hex = "#F4A335";
      break;
    case value > 60:
      rank = "High";
      score = value / 10;
      color = "text-red";
      hex = "#C73C5C";
      break;
    default:
      break;
  }
  return (
    <>
      <div className="progress circular-progress flex justify-center items-center w-3/5 py-6 mx-auto">
        <CircularProgressbarWithChildren
          value={value}
          circleRatio={0.6}
          strokeWidth={8}
          styles={{
            root: {
              transform: "rotate(252deg)",
            },
            path: { stroke: hex, strokeLinecap: "round" },
            trail: { stroke: "#C4C4C4", strokeLinecap: "round" },
          }}
        >
          <div className="progressText flex flex-col items-center -mt-9">
            <div className="score text-primary-600 text-2xl">{score}/10</div>
            <div className={`rank ${color}`}>{rank}</div>
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <p className="last-update-test text-center text-darkGrey opacity-50 -mt-12">Last updated on {date}</p>
    </>
  );
};

export default CircularProgress;
