import React from 'react'

const Card: React.FC<{className?: string}> = ({children,className}) => {
    return (
        <div className={`shadow-xl rounded-lg bg-white p-6 h-full ${className ?? ''}`}>
           {children} 
        </div>
    )
}

export default Card
