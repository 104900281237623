import React from "react";
import CreateAccount from "./CreateAccount";
import AccountDetails from "./AccountDetails";
import AuthLayout from "./AuthLayout";

const Register = () => {
  const [step, setStep] = React.useState(0);

  return (
    <AuthLayout>
      <div>
        {step === 0 && <CreateAccount step={step} setStep={setStep}/>}
        {step === 1 && <AccountDetails/>}

        <div className="form__step-section flex justify-center w-1/2 my-0 mx-auto">
          <button
            className={`form-steps ${step === 1 && `opacity-20`}`}
          />
          <button
            className={`form-steps ${step === 0 && `opacity-20`}`}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default Register;
