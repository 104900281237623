import React from "react";
import { Route, Redirect } from "react-router-dom";
import useLocalStorage from "_utils/useStorage";

const PrivateRoutes = ({ component: Component, ...rest }: any) => {
  const [user] = useLocalStorage("user");
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/register", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoutes;
