const GraphIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M9.93679 20.1902H8.35944C7.57664 20.1902 6.93982 19.5534 6.93982 18.7706V8.991C6.93982 8.2082 7.57664 7.57138 8.35944 7.57138H9.93679C10.7196 7.57138 11.3564 8.2082 11.3564 8.991V18.7706C11.3564 19.5534 10.7196 20.1902 9.93679 20.1902Z"
          fill="currentColor"
        />
        <path
          d="M3.94289 20.1902H2.36554C1.58274 20.1902 0.945923 19.5534 0.945923 18.7706V12.1457C0.945923 11.3629 1.58274 10.7261 2.36554 10.7261H3.94289C4.72569 10.7261 5.36251 11.3629 5.36251 12.1457V18.7706C5.36251 19.5534 4.72565 20.1902 3.94289 20.1902Z"
          fill="currentColor"
        />
        <path
          d="M15.9307 20.1901H14.3534C13.5706 20.1901 12.9338 19.5533 12.9338 18.7705V5.67847H12.067C11.6618 5.67847 11.3048 5.44975 11.1355 5.0816C10.9662 4.71345 11.0248 4.29363 11.2886 3.98593C11.2916 3.98242 11.2946 3.97891 11.2977 3.97548L14.6722 0.209867C14.7919 0.0763044 14.9627 0 15.1421 0C15.3214 0 15.4922 0.0763044 15.612 0.209867L18.9864 3.97548C18.9895 3.97891 18.9926 3.98242 18.9956 3.98593C19.2593 4.29359 19.318 4.71345 19.1486 5.0816C18.9793 5.44975 18.6223 5.67847 18.2171 5.67847H17.3504V18.7705C17.3504 19.5533 16.7135 20.1901 15.9307 20.1901Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20.1901" height="20.1901" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GraphIcon;
