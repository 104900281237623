const ShieldIcon = () => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.59598 6.24854C6.47472 6.24854 4.74902 7.97423 4.74902 10.0953C4.74902 12.2166 6.47472 13.9423 8.59598 13.9423C10.7171 13.9423 12.4428 12.2166 12.4428 10.0953C12.4428 7.97423 10.7171 6.24854 8.59598 6.24854ZM10.8799 9.37089L8.124 12.1268C8.00832 12.2425 7.85659 12.3004 7.70502 12.3004C7.55345 12.3004 7.40187 12.2426 7.28619 12.1268L6.23303 11.0736C6.00166 10.8424 6.00166 10.4673 6.23303 10.236C6.46424 10.0046 6.83932 10.0046 7.07069 10.236L7.70502 10.8703L10.0422 8.53323C10.2735 8.30187 10.6485 8.30187 10.8799 8.53323C11.1113 8.76444 11.1113 9.13953 10.8799 9.37089Z"
        fill="currentColor"
      />
      <path
        d="M17.1729 5.47809L17.1723 5.46238C17.1635 5.26844 17.1577 5.06326 17.1541 4.83498C17.1376 3.72175 16.2527 2.80091 15.1393 2.73868C12.8179 2.60913 11.0222 1.85219 9.4878 0.356636L9.47471 0.344159C8.97424 -0.11472 8.2193 -0.11472 7.71868 0.344159L7.70559 0.356636C6.17121 1.85219 4.37544 2.60913 2.05409 2.73883C0.940856 2.80091 0.0557545 3.72175 0.0392725 4.83513C0.0358836 5.06188 0.0298762 5.26706 0.021096 5.46238L0.0201718 5.49888C-0.0249613 7.86598 -0.0810311 10.812 0.904503 13.4859C1.44641 14.9563 2.26712 16.2346 3.34369 17.2852C4.56984 18.4818 6.17583 19.4318 8.11702 20.1086C8.18018 20.1306 8.2458 20.1485 8.31265 20.1619C8.40692 20.1807 8.50181 20.1901 8.59669 20.1901C8.69158 20.1901 8.78662 20.1807 8.88074 20.1619C8.94759 20.1485 9.01367 20.1305 9.07714 20.1083C11.016 19.4302 12.6203 18.4798 13.8454 17.2834C14.9215 16.2324 15.7422 14.9539 16.2846 13.4831C17.2738 10.8012 17.2179 7.84966 17.1729 5.47809ZM8.59639 15.127C5.82186 15.127 3.56474 12.8699 3.56474 10.0954C3.56474 7.32084 5.82186 5.06373 8.59639 5.06373C11.3708 5.06373 13.628 7.32084 13.628 10.0954C13.628 12.8699 11.3708 15.127 8.59639 15.127Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ShieldIcon;
