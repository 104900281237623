import React, {FC, useState} from "react";
import PageHeading from "../components/Main/PageHeading";
import Button from "../components/Button";
import Layout from "../components/Main/Layout";
import Card from "../components/Card";
import CardContent from "../components/Card/CardContent";
import Unchecked from "../Assets/Unchecked";
import Check from "../Assets/Check";
import Poll from "../components/poll";

const OverviewRiskProfile: FC = () => {
  const [forms, setForms] = useState<{[key in string]: {riskLevel: string, intensity: string, frequency: string, timeframe:string}}>({
    "Asset Age":{riskLevel: '', intensity: '', frequency: '', timeframe:''},
    "Asset failure incidents": {riskLevel: '', intensity: '', frequency: '', timeframe:''},
    "Work Order History": {riskLevel: '', intensity: '', frequency: '', timeframe:''},
    "Frequency of work order requests": {riskLevel: '', intensity: '', frequency: '', timeframe:''},
    "Water quality": {riskLevel: '', intensity: '', frequency: '', timeframe:''},
    "Contaminant type": {riskLevel: '', intensity: '', frequency: '', timeframe:''},
    "Leakages": {riskLevel: '', intensity: '', frequency: '', timeframe:''},
    "Leakage frequency": {riskLevel: '', intensity: '', frequency: '', timeframe:''},
    "Accidents": {riskLevel: '', intensity: '', frequency: '', timeframe:''}
  })
  const [activeForm, setActiveForm] = useState(0)
  const hazardTitle = Object.keys(forms)

  return (
    <div>
      <div className="main-header">
        <PageHeading>
          <div className="button ml-8">
            <Button
              onClick={() => console.log("clicked")}
              className="bg-white font-semibold"
            >
              <span className="mr-3 px-8 text-primary-300">Skip</span>
            </Button>
          </div>
          <div className="button ml-8">
            <Button
              onClick={() => console.log("clicked")}
              className="bg-main hover:bg-primary-300 font-semibold"
            >
              <span className="mr-3">Save and Exit</span>
            </Button>
          </div>
        </PageHeading>
      </div>
      <Layout>
        <div className="col-span-1">

          <Card className="px-0">
            <h2 className="title text-primary-600 text-2xl px-5 pb-3 font-semibold">Climate Hazard Type</h2>
            <CardContent>
              {hazardTitle.map((value, index) => (
                <div key={index} className={`${activeForm === index ? "risk-bg-color pt-1" : ""} px-6`}>
                  <div className="my-5 flex justify-start items-center">
                    {Object.values(forms[value]).every((el) => el)? <Check/> :
                      <Unchecked radius={'9'} color={activeForm === index ? '#0088B2' : '#D8DFE1'}
                                 strokeWidth={activeForm === index ? '2' : '1'}/>}
                    <span
                      className={`${activeForm === index ? "text-main" : `${activeForm > index ? "text-main" : ""}`} ml-5 leading-7`}>{value}</span>
                  </div>
                  <hr/>
                </div>
              ))}
            </CardContent>
          </Card>

        </div>

        <div className="col-span-2">

          <Card>
            <h2 className="title text-primary-600 text-2xl font-semibold">{hazardTitle[activeForm]}</h2>
            <Poll titles={hazardTitle} forms={forms} activeForm={activeForm} setForm={setForms} setActiveForm={setActiveForm}/>
          </Card>

        </div>
      </Layout>
    </div>
  );
};

export default OverviewRiskProfile;
