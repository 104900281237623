import React from "react";

const Unchecked : React.FC<{radius?:string, color?:string, strokeWidth?:string}> = ({radius, color, strokeWidth}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r={radius} stroke={color} strokeWidth={strokeWidth}/>
    </svg>
  )
}

export default Unchecked;