import { MenuItemType } from 'types';
import IconTextLink from 'components/IconTextLink';
import { menuItems } from '_utils/site-data';

const Menu = () => {
    return (
        <div className="flex flex-col justify-center mt-14">
            {menuItems && menuItems.length > 0 && (
                menuItems.map((menuItem: MenuItemType, index : number) => (
                    <IconTextLink menuData={menuItem} key={index} />
                ))
            )}
        </div>
    )
}

export default Menu
