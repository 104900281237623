import { DefaultNode, Graph } from "@visx/network";

export type NetworkProps = {
  width: number;
  height: number;
};

interface CustomNode {
  x: number;
  y: number;
  color?: string;
}

interface CustomLink {
  source: CustomNode;
  target: CustomNode;
  text?: string;
  dashed?: boolean;
}

const nodes = [
  { x: 30, y: 20, color: "#0F617B", size: "small" },
  { x: 200, y: 32 },
  { x: -30, y: 120 },
  { x: 80, y: 200 },
  { x: 250, y: 260, color: "#0F617B" },
  { x: -10, y: 490, color: "#0F617B" },
  { x: 250, y: 400 },

//   { x: 100, y: 40 },
//   { x: 250, y: 100 },
];

const links: CustomLink[] = [
  { source: nodes[0], target: nodes[0], text: "Health Outbreak" },
  { source: nodes[0], target: nodes[1]},
  { source: nodes[0], target: nodes[3]},
  { source: nodes[0], target: nodes[6]},
  { source: nodes[0], target: nodes[4]},
  { source: nodes[1], target: nodes[3]},
  { source: nodes[1], target: nodes[5]},
  { source: nodes[2], target: nodes[3]},
  { source: nodes[2], target: nodes[5]},
  { source: nodes[3], target: nodes[5]},
  { source: nodes[5], target: nodes[6]},
  { source: nodes[3], target: nodes[6]},
  { source: nodes[5], target: nodes[4]},
  { source: nodes[1], target: nodes[1], text: "Water Infection" },
  { source: nodes[2], target: nodes[2], text: "Extreme Weather" },
  { source: nodes[3], target: nodes[3], text: "Natural Disaster" },
  { source: nodes[4], target: nodes[4], text: "Paleoclimate Proxies" },
  { source: nodes[5], target: nodes[5], text: "Climate Change" },
  { source: nodes[6], target: nodes[6], text: "Socioeconomic Ranking" },
  
];

const graph = {
  nodes,
  links,
};

const NetworkChart = () => {
  const width = "100%";
  const height = "600";
  return (
    <svg width={width} height={height}>
      <rect width={width} height={height} rx={14} fill={"transparent"} />
      <Graph<CustomLink, CustomNode>
        graph={graph}
        top={30}
        left={110}
        nodeComponent={({ node: { color } }) =>
          color ? <DefaultNode fill={color} /> : <DefaultNode />
        }
        linkComponent={({ link: { source, target, dashed, text } }) => (
          <>
            <text
              alignment-baseline="middle"
              text-anchor="middle"
              fill="#5C6D73"
              font-size="14"
              x={source.x - 40}
              y={source.y - 25}
            >
              {text?.split(" ")[1]}
            </text>
            <text
              alignment-baseline="middle"
              text-anchor="middle"
              fill="#5C6D73"
              font-size="14"
              x={source.x - 40}
              y={source.y - 40}
            >
              {text?.split(" ")[0]}
            </text>
            <line
              x1={source.x}
              y1={source.y}
              x2={target.x}
              y2={target.y}
              strokeWidth={1}
              stroke="#8D999E"
              strokeOpacity={0.6}
              strokeDasharray={dashed ? "8,4" : undefined}
            />
          </>
        )}
      />
    </svg>
  );
};

export default NetworkChart;
