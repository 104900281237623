
const SearchIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1632 15.4843L12.1172 11.2762C13.1575 10.0396 13.7275 8.48359 13.7275 6.86374C13.7275 3.07913 10.6484 0 6.86374 0C3.07913 0 0 3.07913 0 6.86374C0 10.6484 3.07913 13.7275 6.86374 13.7275C8.28454 13.7275 9.63849 13.299 10.7961 12.4854L14.8728 16.7255C15.0432 16.9024 15.2724 17 15.518 17C15.7505 17 15.971 16.9114 16.1385 16.7502C16.4942 16.4079 16.5055 15.8403 16.1632 15.4843ZM6.86374 1.79054C9.66117 1.79054 11.9369 4.06632 11.9369 6.86374C11.9369 9.66117 9.66117 11.9369 6.86374 11.9369C4.06632 11.9369 1.79054 9.66117 1.79054 6.86374C1.79054 4.06632 4.06632 1.79054 6.86374 1.79054Z" fill="#C1C9CB" />
        </svg>
    )
}

export default SearchIcon
