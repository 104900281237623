import React, {FC} from 'react';
import Check from "../../Assets/Check";
import Select from 'react-select';

type SelectT = FC<{ accountForm: any, name: any }>
type InputT = FC<any> & { Select: SelectT }

const Input: InputT = ({value, name, type, onChange, onBlur, placeholder}) => {
  return (
    <div className="flex justify-between relative">
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className="border border-gray-300 rounded-md px-4 py-3 w-full"
      />
      <div className="mt-4 ml-5 absolute -right-8">
        {value && <Check/>}
      </div>
    </div>
  );
};

const SelectDropdown: SelectT = ({accountForm, name}) => {
  const dropdownValue = [
    { value: '', label: 'Choose', isDisabled: true },
    { value: 'Manager', label: 'Manager' },
    { value: 'Technician', label: 'Technician' },
    { value: 'Lab Worker', label: 'Lab Worker' },
  ];

  return (
    <div className="relative flex justify-between">
      <div className="relative w-full">
        <Select
          name={name}
          value={accountForm.values.role}
          onChange={(v: any) => accountForm.handleChange({target:{name: 'role', value:v}})}
          options={dropdownValue}
        />
      </div>
      <div className="mt-3 ml-5 absolute -right-8">
        {accountForm.values.role.value && <Check/>}
      </div>
    </div>
  )
}

Input.Select = SelectDropdown

export default Input;