const Brand = () => {
  return (
    <svg
      width="100%"
      height="inherit"
      viewBox="0 0 155 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3242 0.140015L24.0358 22.7209H30.3934L32.3311 17.7543H39.6824L41.5205 22.7209H47.8792L38.5897 0.140015H33.3242ZM36.0068 6.30274L38.1922 13.5233H33.523L36.0068 6.30274Z"
        fill="#0088B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.4083 4.18697H56.4774V9.79816H59.4083C61.6933 9.79816 62.6369 8.4185 62.6369 6.99202C62.6369 5.5209 61.7924 4.18697 59.4083 4.18697ZM62.8852 22.7214L57.7193 13.7068H56.4774V22.7214H50.5663V0.140015H60.8984C65.1207 0.140015 68.6973 1.70315 68.6973 6.90055C68.6973 10.6715 66.7601 12.6489 63.8788 13.2473L69.6408 22.7214H62.8852Z"
        fill="#0088B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.8869 21.5257C89.1479 22.6293 87.3098 23.1814 83.6336 23.1814C79.909 23.1814 77.9217 22.6293 76.1332 21.5257C73.5998 20.0077 72.7058 18.26 72.7058 13.845V0.139893H78.617V14.0753C78.617 16.8804 80.6037 18.7658 83.8324 18.7658C87.2107 18.7658 88.7004 16.4203 88.7004 14.0753V0.139893H94.4629V13.845C94.4629 17.8925 93.4698 19.962 90.8869 21.5257Z"
        fill="#0088B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.701 22.767L105.071 8.14234V22.7213H99.6069V0.139893H105.965L116.099 14.8566V0.139893H121.563V22.767H115.701Z"
        fill="#0088B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.783 6.30262L134.299 13.5232H138.968L136.783 6.30262ZM142.296 22.7208L140.459 17.7537H133.106L131.17 22.7208H124.812L134.1 0.139893H139.366L148.655 22.7208H142.296Z"
        fill="#0088B2"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="13"
        y="0"
        width="14"
        height="23"
      >
        <path d="M13.3981 0H26.5854V22.3835H13.3981V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.5854 -0.000488281H18.9494C18.9494 -0.000488281 15.9538 6.65443 13.3981 12.3925C15.1213 16.1258 16.6469 19.471 16.9899 20.3432C17.2991 21.1305 17.1968 21.817 16.8739 22.3833C17.1233 22.0523 17.3612 21.6657 17.5817 21.2122C18.9249 18.4468 26.5854 -0.000488281 26.5854 -0.000488281Z"
          fill="#6CC5D6"
        />
      </g>
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="23"
      >
        <path d="M0 0H13.3986V22.3832H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.91785 20.3427C10.2603 19.4705 11.7363 16.1253 13.3986 12.3925C10.7498 6.65448 7.63548 -0.000976562 7.63548 -0.000976562H0C0 -0.000976562 7.98339 18.4464 9.32603 21.2117C9.54653 21.6657 9.78446 22.0523 10.0338 22.3828C9.71096 21.8171 9.6086 21.13 9.91785 20.3427Z"
          fill="#0088B2"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9899 20.3431C16.6474 19.4709 15.1213 16.1258 13.3986 12.3929C11.7358 16.1258 10.2603 19.4709 9.91788 20.3431C9.60863 21.1304 9.71099 21.8175 10.0339 22.3832C11.0955 23.7912 12.3696 24.162 13.4541 24.1679C14.5382 24.162 15.8127 23.7912 16.8739 22.3832C17.1967 21.8175 17.2991 21.1304 16.9899 20.3431Z"
        fill="#009FC4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.129 20.5699V22.7342H150.635V20.5699H149.86V20.1512H151.903V20.5699H151.129Z"
        fill="#007D9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.531 22.7345V20.8403C154.511 20.9731 154.515 20.9933 154.433 21.2083L153.842 22.7345H153.399L152.859 21.2356C152.805 21.0864 152.801 21.0439 152.761 20.8245V22.7345H152.316V20.151H152.906L153.595 21.9945C153.623 22.0691 153.634 22.0964 153.646 22.1944C153.662 22.0729 153.67 22.0572 153.693 21.9945L154.409 20.151H155V22.7345H154.531Z"
        fill="#007D9F"
      />
    </svg>
  );
};

export default Brand;
