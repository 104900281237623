const ZoomIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6343 10.1511L18.958 12.6243C18.9182 12.6511 18.8943 12.6959 18.8943 12.7439V16.2667C18.8943 16.3147 18.9182 16.3595 18.958 16.3863L22.6343 18.8595C22.7301 18.9239 22.859 18.8553 22.859 18.7399V10.2707C22.859 10.1553 22.7301 10.0867 22.6343 10.1511Z"
        fill="#2D8CFF"
      />
      <path
        d="M7 10.0002H16.0827C17.3567 10.0002 18.3894 11.033 18.3894 12.307V18.9388H9.30672C8.03276 18.9388 7 17.906 7 16.6321V10.0002Z"
        fill="#2D8CFF"
      />
      <circle
        cx="14.5"
        cy="14.5"
        r="14.1282"
        stroke="white"
        strokeOpacity="0.5"
        strokeWidth="0.74359"
      />
    </svg>
  );
};

export default ZoomIcon;
