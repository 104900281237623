import HelpIcon from 'Assets/HelpIcon';
import SearchInput from 'components/Search';
import { Link } from 'react-router-dom';
import './Nav.css';
import Badge from 'components/Badge'
import MessageIcon from 'Assets/MessageIcon';
import BellIcon from 'Assets/BellIcon';
import AdminIcon from 'Assets/admin.png';
 
const Nav = () => {
    const handleSearchSubmit = (search: string) => {
        console.log(search);
    };

    return (
        <nav className="nav-component">
            <div className="nav-columns flex items-center">
                <SearchInput onSubmit={handleSearchSubmit} name="nav-search"/>
                <Link to="/help" className="help ml-5 flex">
                    <HelpIcon />
                    <span className="ml-2 text-secondary-200">Help</span>
                </Link>
            </div>
            <div className="nav-columns flex space-x-10 items-center">
                <Badge badgeContent={2}>
                    <MessageIcon />
                </Badge>
                <Badge badgeContent={3}>
                    <BellIcon />
                </Badge>
                <h3 className="admin-name text-secondary-200 text-center">Sean Nagle</h3>
                <div className="admin-icon">
                    <img src={AdminIcon} alt="admin" className="rounded-full"/>
                </div>
            </div>
        </nav>
    )
}

export default Nav
