import React from 'react';
import DualRing from "../../Assets/DualRing/index";

const Success: React.FC<{title: string}> = ({title}) => {
    return(
    <div className="absolute z-10 top-0 left-0 right-0 bottom-0">
      <div className='h-full w-full flex items-center justify-center flex-col'>
        <p className="text-primary-300 text-2xl">{title}</p>
        <DualRing />
      </div>
    </div>
  )
}
export default Success;
