import { VictoryChart, VictoryScatter, VictoryAxis, VictoryContainer } from "victory";

const ScatterPoint = ({ x, y, datum }: any) => {
  return (
    <g>
      <rect
        x={x - 25}
        y={y - 10}
        rx="10"
        ry="10"
        width="66"
        height="20"
        stroke={"white"}
        strokeWidth={2}
        fill={datum.fill}
      />
      <text
        x={x- 25 + (66 / 2)}
        y={y - 10 + (20 / 2)}
        alignment-baseline="middle"
        text-anchor="middle"
        fill="white"
        font-size="9.5"
      >
        {datum.text}
      </text>
    </g>
  );
};

const LIGHT_GREY = "#8D999E";

const sharedAxisStyles = {
  axis: {
    stroke: "transparent",
  },
  tickLabels: {
    fill: LIGHT_GREY,
    fontSize: 8,
    textTransform: "uppercase", 
  },
};

const ScatterPlot = () => {
  return (
    <VictoryChart domainPadding={{x: 25}} height={210}>
      <VictoryAxis
      tickValues={[1, 2, 3]}
      tickFormat={["Low", "Medium", "High"]}
        style={{
          ...sharedAxisStyles,
        }}
      />
      <VictoryAxis
        dependentAxis
        tickFormat={["0", "Low", "Medium", "High"]}
        style={{
          ...sharedAxisStyles,
          grid: {
            fill: LIGHT_GREY,
            stroke: LIGHT_GREY,
            pointerEvents: "painted",
            strokeWidth: 0.5,
          },
        }}
      />
      <VictoryScatter
        data={[
          { x: 1, y: 4, text: "Inv Con", fill: "#C73C5C" },
          { x: 2, y: 3, text: "Hacker", fill: "#F4A335" },
          { x: 2, y: 2, text: "Lawsuits", fill: "#74A242" },
          { x: 3, y: 3, text: "Acts of God", fill: "#F4A335" },
        ]}
        containerComponent={<VictoryContainer responsive={true}/>}
        dataComponent={<ScatterPoint />}
      />
    </VictoryChart>
  );
};

export default ScatterPlot;
