import React from "react";

const Edit = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2504 8.99975C13.8357 8.99975 13.5004 9.33581 13.5004 9.74973V15.75C13.5004 16.1632 13.1644 16.4999 12.7505 16.4999H2.25007C1.83601 16.4999 1.50009 16.1632 1.50009 15.75V5.24958C1.50009 4.83635 1.83601 4.49961 2.25007 4.49961H8.25031C8.66506 4.49961 9.00029 4.16355 9.00029 3.74963C9.00029 3.33557 8.66506 2.99951 8.25031 2.99951H2.25007C1.00954 2.99951 0 4.00905 0 5.24958V15.75C0 16.9905 1.00954 18 2.25007 18H12.7505C13.991 18 15.0005 16.9905 15.0005 15.75V9.74973C15.0005 9.33498 14.6652 8.99975 14.2504 8.99975Z"
        fill="currentColor"
      />
      <path
        d="M7.03187 8.31651C6.97941 8.36897 6.94412 8.43571 6.92915 8.50767L6.3989 11.1599C6.37418 11.2828 6.41318 11.4095 6.50163 11.4988C6.5729 11.5701 6.6689 11.6083 6.76723 11.6083C6.79113 11.6083 6.81598 11.6061 6.8407 11.6008L9.49206 11.0706C9.56554 11.0555 9.63228 11.0203 9.68406 10.9677L15.6182 5.03355L12.9669 2.38232L7.03187 8.31651Z"
        fill="currentColor"
      />
      <path
        d="M17.451 0.548476C16.7199 -0.182825 15.5303 -0.182825 14.7997 0.548476L13.7617 1.58644L16.4131 4.2378L17.451 3.1997C17.8051 2.84648 18.0001 2.37542 18.0001 1.87443C18.0001 1.37344 17.8051 0.902384 17.451 0.548476Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Edit;
