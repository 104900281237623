import AlertIcon from 'Assets/AlertIcon';
import GraphIcon from 'Assets/GraphIcon';
import ShieldIcon from 'Assets/ShieldIcon';
import Pill from 'components/Pill';

export const pageheadingTitle = [
  {
    url: "/overview",
    title: "Risks Overview",
  },
  {
    url: "/overview/internal-risk",
    title: "Internal Risks",
  },
  {
    url: "/overview/external-risk",
    title: "External Risks",
  },
  {
    url: "/team",
    title: "Team",
  },
  {
    url: "/overview/internal-risk-profile",
    title: "Internal Risk Profile",
  },
  {
    url: "/overview/risk-profile",
    title: "Risk Profile",
  },
];


// Sidebar Menu
export const menuItems = [
    {
        menuIcon: <GraphIcon />,
        menuLabel: 'Overview',
        menuLink: '/overview',
    },
    // {
    //     menuIcon: <TeamIcon />,
    //     menuLabel: 'Team',
    //     menuLink: 'team',
    // },
    {
        menuIcon: <AlertIcon />,
        menuLabel: 'Alerts',
        menuLink: 'alert',
    },
    {
        menuIcon: <ShieldIcon />,
        menuLabel: 'Quality',
        menuLink: 'quality',
    },
    // {
    //     menuIcon: <IncomeIcon />,
    //     menuLabel: 'Finance',
    //     menuLink: 'finance',
    // },

];


export const tableExtra = [
  {
    tr: [{ td: "State:" }, { td: "New Jersey" }],
  },
  {
    tr: [{ td: "Connection Count:" }, { td: "75000" }],
  },
];

export const tableExtraFalse = [
  {
    tr: [{ td: "State:" }, { td: "-" }],
  },
  {
    tr: [{ td: "Connection Count:" }, { td: "-" }],
  },
];

export const hazardType = ["Asset Age", "Asset failure incidents", "Work Order History", "Frequency of work order requests",
"Water quality", "Contaminant type", "Leakages", "Leakage frequency", "Accidents"];

export let hazardRiskLevel = [
  {value: 'Low', color:  '#74A242'},
  {value: 'Moderate', color:  '#F4A335'},
  {value: 'High', color:  '#C73C5C'},
  {value: 'Not Known', color:  '#0088B2'}
]

export const changeIntensity = [
  {value: 'Increase', color:  '#0088B2'},
  {value: 'Decrease', color:  '#0088B2'},
  {value: 'No Change', color:  '#0088B2'},
  {value: 'Not Known', color:  '#0088B2'}
]

export const timeFrame = [
  {value: 'Current', color:  '#0088B2'},
  {value: 'Short-Term', color:  '#0088B2'},
  {value: 'Medium-Term', color:  '#0088B2'},
  {value: 'Long-Term', color:  '#0088B2'},
  {value: 'Not Known', color:  '#0088B2'}
]

export const bigTableDataHeader = [
  {
    th: "Object ID",
  },
  {
    th: "Name",
  },
  {
    th: "Type",
  },
  {
    th: "District",
  },
  {
    th: "Country",
  },
  {
    th: "Plat Access",
  },
];

export const parameterTableHeader = [
  {
    th: "Parameter"
  },
  {
    th: "Impact"
  },
  {
    th: "Likelihood"
  },
];

const pillsContent = [
  <Pill bg="bg-greenOpaque" color="text-green">Low</Pill>,
  <Pill bg="bg-orangeOpaque" color="text-orange">Moderate</Pill>,
  <Pill bg="bg-redOpaque" color="text-red">High</Pill>
]
export const parameterTableBody = [
  {
    tr: [
      { td: "Water Quality" },
      { td: "High" },
      { td: pillsContent[0] },
    ],
  },
  {
    tr: [
      { td: "Cybersecurity" },
      { td: "High" },
      { td: pillsContent[2] },
    ],
  },
  {
    tr: [
      { td: "Leakages" },
      { td: "High" },
      { td: pillsContent[0] },
    ],
  },
  {
    tr: [
      { td: "Water Quality" },
      { td: "High" },
      { td: pillsContent[2] },
    ],
  },
  {
    tr: [
      { td: "Water Quality" },
      { td: "High" },
      { td: pillsContent[1] },
    ],
  },
  {
    tr: [
      { td: "Cybersecurity" },
      { td: "High" },
      { td: pillsContent[2] },
    ],
  },
  {
    tr: [
      { td: "Leakages" },
      { td: "High" },
      { td: pillsContent[0] },
    ],
  },
  {
    tr: [
      { td: "Water Quality" },
      { td: "High" },
      { td: pillsContent[1] },
    ],
  },
  {
    tr: [
      { td: "Water Quality" },
      { td: "High" },
      { td: pillsContent[1] },
    ],
  },
  {
    tr: [
      { td: "Water Quality" },
      { td: "High" },
      { td: pillsContent[1] },
    ],
  },
  {
    tr: [
      { td: "Water Quality" },
      { td: "High" },
      { td: pillsContent[1] },
    ],
  },
 
];

export const bigtableBody = [
  {
    tr: [
      { td: "344" },
      { td: "Cotton Center" },
      { td: "MUD" },
      { td: "4739825" },
      { td: "Caldwell" },
      { td: "9063.135" },
    ],
  },
  {
    tr: [
      { td: "857" },
      { td: "Ranch At Clear Fork Creek" },
      { td: "MUD" },
      { td: "2404409" },
      { td: "Caldwell" },
      { td: "4799.4113" },
    ],
  },
  {
    tr: [
      { td: "284" },
      { td: "Caldwell County" },
      { td: "MUD" },
      { td: "1862500" },
      { td: "Caldwell" },
      { td: "3942.2274" },
    ],
  },
];

export const dateItems = [
  { name: "Date" },
  { name: "Monday" },
  { name: "Tuesday" },
  { name: "Wednesday" },
  { name: "Thursday" },
  { name: "Friday" },
];

export const dateItemsFalse = [
  { name: "Date" },
  { name: "" }
];

export const internalRiskNotes = [
  {
    title: "CyberSecurity",
    link: "/",
    description: "Cybersecurity risks are high because the last OS update implemented was six month ago."
  },
  {
    title: "Leakage Risks",
    link: "/",
    description: "Leakage risks are high due to external..."
  },
];

export const externalRiskNotes = [
  {
    title: "Inverse Condemnation",
    link: "/",
    description: "Inverse condemnation likelyhood is high due to pothole issues causing more impact on roadside water system assets. "
  },
];