const SettingsIcons = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12.7812V8.21874H18.3439C18.1773 7.64489 17.948 7.09219 17.6588 6.56731L19.6324 4.59375L16.4062 1.36761L14.4327 3.34117C13.9078 3.05201 13.3551 2.82275 12.7812 2.65618V0H8.21874V2.65618C7.64489 2.82271 7.09219 3.05201 6.56731 3.34117L4.59375 1.36761L1.36761 4.59375L3.34117 6.56731C3.05201 7.09219 2.82275 7.64489 2.65618 8.21874H0V12.7812H2.65618C2.82275 13.3551 3.05201 13.9078 3.34117 14.4326L1.36761 16.4062L4.59375 19.6323L6.56731 17.6588C7.09219 17.948 7.64489 18.1772 8.21874 18.3438V21H12.7812V18.3438C13.3551 18.1772 13.9078 17.948 14.4327 17.6588L16.4614 19.6875L19.6875 16.4613L17.6588 14.4326C17.948 13.9078 18.1773 13.3551 18.3439 12.7812H21ZM10.5 16.1101C7.40661 16.1101 4.88995 13.5934 4.88995 10.5C4.88995 7.40661 7.40661 4.88995 10.5 4.88995C13.5934 4.88995 16.1101 7.40661 16.1101 10.5C16.1101 13.5934 13.5934 16.1101 10.5 16.1101Z"
        fill="currentColor"
      />
      <circle cx="10.5" cy="10.5" r="3.5" fill="currentColor" />
    </svg>
  );
};

export default SettingsIcons;
