const HelpIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 20.4902C13.7011 20.4902 14.2695 19.9218 14.2695 19.2207C14.2695 18.5196 13.7011 17.9512 13 17.9512C12.2989 17.9512 11.7305 18.5196 11.7305 19.2207C11.7305 19.9218 12.2989 20.4902 13 20.4902Z"
        fill="#C1C9CB"
      />
      <path
        d="M13 0C5.81527 0 0 5.8143 0 13C0 20.1847 5.8143 26 13 26C20.1847 26 26 20.1857 26 13C26 5.81527 20.1857 0 13 0ZM13 23.9688C6.93789 23.9688 2.03125 19.0629 2.03125 13C2.03125 6.93789 6.93707 2.03125 13 2.03125C19.0621 2.03125 23.9688 6.93707 23.9688 13C23.9688 19.0621 19.0629 23.9688 13 23.9688Z"
        fill="#C1C9CB"
      />
      <path
        d="M13 6.52539C10.7599 6.52539 8.9375 8.34783 8.9375 10.5879C8.9375 11.1488 9.3922 11.6035 9.95312 11.6035C10.5141 11.6035 10.9688 11.1488 10.9688 10.5879C10.9688 9.46786 11.88 8.55664 13 8.55664C14.12 8.55664 15.0312 9.46786 15.0312 10.5879C15.0312 11.7079 14.12 12.6191 13 12.6191C12.4391 12.6191 11.9844 13.0738 11.9844 13.6348V16.1738C11.9844 16.7348 12.4391 17.1895 13 17.1895C13.5609 17.1895 14.0156 16.7348 14.0156 16.1738V14.5219C15.7659 14.0698 17.0625 12.4774 17.0625 10.5879C17.0625 8.34783 15.2401 6.52539 13 6.52539Z"
        fill="#C1C9CB"
      />
    </svg>
  );
};

export default HelpIcon;
