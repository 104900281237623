import React from 'react';

const
  DownArrow = () => {
    return (
      <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.06647 19.6908L5.06547 19.6898L0.305287 14.9911C-0.103112 14.5783 -0.101593 13.9108 0.308851 13.5C0.719243 13.0892 1.38303 13.0908 1.79148 13.5035L4.76018 16.392L4.76018 1.05431C4.76018 0.472015 5.22954 0 5.80857 0C6.38759 0 6.85696 0.472015 6.85696 1.05431L6.85696 16.392L9.82595 13.5035C10.2344 13.0908 10.8982 13.0892 11.3086 13.5C11.7191 13.9108 11.7205 14.5784 11.3121 14.9911L6.55166 19.6899L6.55067 19.6908C6.14086 20.1037 5.47492 20.1024 5.06647 19.6908Z"
          fill="#74A242"/>
      </svg>
    );
  };

export default DownArrow;