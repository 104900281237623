const LogOutIcon = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50078 0C14.4567 0 18.5213 3.79513 18.9584 8.63707H11.2282V6.04595C11.2282 5.69701 11.0175 5.38176 10.6953 5.24789C10.3723 5.11401 10.0009 5.18829 9.75385 5.43531L6.29902 8.89014C5.96131 9.22785 5.96131 9.77371 6.29902 10.1114L9.75385 13.5663C10.0009 13.8133 10.3723 13.8875 10.6953 13.7537C11.0175 13.6198 11.2282 13.3045 11.2282 12.9556V10.3645H18.9584C18.5213 15.2064 14.4567 19.0016 9.50078 19.0016C4.25376 19.0016 0 14.7478 0 9.50078C0 4.25376 4.25376 0 9.50078 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LogOutIcon;
