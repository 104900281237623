import { integrationItemType } from "types"

const IconTextButton : React.FC<{ data: integrationItemType }> = ({data,...props}) => {
    return (
        <button className="flex transition items-center text-white opacity-50 hover:opacity-100 mb-8" {...props}>
            <span className="menu-item-icon">{data.icon}</span>
            <div className="menu-item-label ml-4">{data.title}</div>
        </button>
    )
}

export default IconTextButton
