import GoogleIcon from 'Assets/GoogleIcon'
import Button from 'components/Button'
import Heading from 'components/Heading'
import {Link} from 'react-router-dom'
import WhiteArrow from "../../Assets/WhiteArrow";
import Input from "./Input";
import {useFormik} from "formik";
import {FC} from "react";

const CreateAccount: FC<{step: number, setStep: (any: any) => void}> = ({step,setStep}) => {
  const createForm = useFormik({
    initialValues: {email: '', password: ''},
    validate: (values: { email: any; password: any; }) => {
      const errors: any = {}
      if (!values.email) errors.email = 'Email is required'
      if (!values.password) errors.password = 'Password is required'
      return errors;
    },
    onSubmit: (values: any, {resetForm, setSubmitting}: any) => {
      console.log(values)
      setStep(step + 1)
      // resetForm()
      // setTimeout(() => {
      //   setSubmitting(false)
      // }, 3000);
    }
  })
  return (
    <div>
      <div className="text-center mt-3 flex flex-col justify-center items-center">
        <Heading text="Create an Account" type="medium"/>
        <p className="sub-heading mt-4 mb-12 text-darkGrey">Already have an account? <Link to="/login" className="">Sign
          in</Link></p>
        <Button onClick={() => console.log("clicked")}
                className="google-button bg-transparent font-medium text-secondary-200 border border-gray-300 w-full flex items-center">
          <span className="w-4 h-4"><GoogleIcon/></span>
          <span className="text-semibold inline-block ml-4">Sign up with Google</span>
        </Button>
        <span className="form_divider my-7 text-secondary-200 block w-full">Or</span>
      </div>
      <div>
        <form onSubmit={createForm.handleSubmit}>
          <div className="flex flex-col mb-5 w-full">
            <label className="text-secondary-200 mb-2">Your Email</label>
            <Input
              type="email"
              name="email"
              value={createForm.values.email}
              onChange={createForm.handleChange}
              onBlur={createForm.handleBlur}
              placeholder="ex.name@gmail.com"
            />
          </div>
          <div className="flex flex-col mb-5 w-full">
            <label className="text-secondary-200 mb-2">Set Password</label>
            <Input
              type="password"
              name="password"
              value={createForm.values.password}
              onChange={createForm.handleChange}
              onBlur={createForm.handleBlur}
              placeholder="8+ characters, 1 Capital Letter"
            />
          </div>
          <div className="mt-10 mb-20">
            <Button className="google-button bg-main font-medium text-white border border-gray-300 w-full">
              <span className="text-semibold mr-3">Next</span>
              <span className="w-4 h-4 mt-2"><WhiteArrow/></span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateAccount
