import GaugeChart from "react-gauge-chart";
import {FC} from "react";

const Guage: FC<{submitted: boolean, percent: number}> = ({submitted, percent}) => {
  let title, description, color;
  switch (true) {
    case percent === 0:
      title = "RISK LEVEL";
      description = "Could occur at some future time";
      color = "text-gray";
      break;
    case percent > 0 && percent <= 0.3:
      title = "LOW";
      description = "Could occur at some future time (0.1%)";
      color = "text-green";
      break;
    case percent > 0.3 && percent <= 0.6:
      title = "MEDIUM";
      description = "Probably occur in most circumstances (10%)";
      color = "text-yellow";
      break;
    case percent > 0.6:
      title = "HIGH";
      description = "This was not given";
      color = "text-red";
      break;
    default:
      break;
  }

  return (
    <div className="flex justify-between items-center w-full">
      <div className="title-and-description w-1/3 mt-5">
        <h3 className={`guage-title text-2xl ${color}`}>{title}</h3>
        <p className="guage-description text-sm mt-1 text-darkGrey">
          {description}
        </p>
      </div>
      <div className="w-2/3">
        <GaugeChart
          id="gauge-chart3"
          nrOfLevels={3}
          colors={submitted ? ["#74A242", "#F4A335", "#C73C5C"] : ["#0088B22b","#0088B22b","#0088B22b"]}
          arcWidth={0.3}
          percent={percent}
          arcPadding={0.02}
          hideText={true}
          cornerRadius={3}
        />
      </div>
    </div>
  );
};

export default Guage;
