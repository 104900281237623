import React from 'react'
import { BadgeType } from 'types'
import './badge.css';

const Badge: React.FC<BadgeType> = ({children, badgeContent}) => {
    return (
        <div className="relative cursor-pointer">
            <span className="badge-content">{badgeContent}</span>
            {children}
        </div>
    )
}

export default Badge
