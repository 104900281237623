const WhiteArrow = () => {
  return (
    <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7681 3.79985L14.7674 3.7991L11.2433 0.228965C10.9338 -0.0773343 10.4331 -0.0761944 10.125 0.231638C9.81693 0.539432 9.81812 1.03727 10.1276 1.34361L12.294 3.57014H0.790734C0.354012 3.57014 0 3.92216 0 4.35643C0 4.79069 0.354012 5.14272 0.790734 5.14272H12.294L10.1276 7.36946C9.81812 7.6758 9.81693 8.17364 10.125 8.48144C10.4331 8.78931 10.9338 8.79037 11.2433 8.48411L14.7674 4.91375L14.7681 4.913C15.0778 4.60564 15.0768 4.10619 14.7681 3.79985Z"
        fill="white"/>
    </svg>
  )
}
export default WhiteArrow;
