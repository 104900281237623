import Heading from "components/Heading";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { pageheadingTitle } from "_utils/site-data";

const PageHeading: React.FC<RouteComponentProps> = (props) => {
  const pathname = props.location.pathname.slice(1).toString();

  const [title, setTitle] = React.useState<string | undefined>("");

  React.useEffect(() => {
    const foundPath = pageheadingTitle.find(
      ({ url }) => url === `/${pathname}`
    );
    setTitle(foundPath?.title);
  }, [pathname]);

  return (
    <header className="flex justify-between items-center">
      <Heading text={title!} type="large"/>
      <div className="header-menu-holder flex justify-between items-center">
        {props.children}
      </div>
    </header>
  );
};

export default withRouter(PageHeading);
